import {Card, Badge, Col, Button, Form, Toast, ListGroup, Nav, Navbar, NavDropdown , FormControl, InputGroup} from 'react-bootstrap'
import React, {useRef, useState, useEffect, useMemo} from "react";
import {gql, useMutation, useQuery} from '@apollo/client'


const storemenus = gql`
{
  getVendorStoreMenus{
    status
	code
    ... on VendorStoreMenuResponse{
        vendorstoremenus{
        menus{
           menuName
           subMenus
           status
        }
        vendorName
        }
    }
  }
}
`

const  updateVendorStoreMenuM = gql `
    mutation updateVendorStoreMenu($storeMenus: [StoreMenuInput!]!){
      updateVendorStoreMenu(storeMenus: $storeMenus){
        status
        code
        ... on AcknowledgedResponse{
        	data
      	}
      }
    }
  `




const server = process.env.REACT_APP_IMAGE_URL

export const StoreMenuPage = props => {
const {loading, error, data} = useQuery(storemenus, {fetchPolicy: "no-cache"})

function goBack(event){
  props.requestcomponent(props.data.sender, { requestcomponent: props.requestcomponent})
  
}

  return (
    <>
  

    
          <div className="row-fluid  ">
           <Card.Header className="">
           <div className="row ">
             <div className="col-md-4">
             <a href="#" className="forestGreen text-start "> <span onClick={e => goBack(e)} ><i className="fas fa-arrow-left"></i> Back </span> </a>
          	</div>
          	  <div className="col-md-4">
            <h5 className=" text-center darkSlateGray">Store Menus</h5>
          
            </div>
              <div className="col-md-4">
              </div>
            </div>
            </Card.Header>
          </div>
          <hr/>
    
          {
          		!loading &&

          <div className="container fontLato">
   				<VendorStoreMenuDataPage data={data?.getVendorStoreMenus.vendorstoremenus}/>
          </div>
          }

    </>
  )


};




export const VendorStoreMenuDataPage = props => {
const [show, setShow] = useState(false);
let holderMenus = []
for(const menu of props.data.menus){
    let menuData = menu 
    delete menuData.__typename
    holderMenus = holderMenus.concat(menuData)
    
}
const [state, setState] = useState({edit: false, storeMenus: holderMenus, vendorName: props.data.vendorName,  menuHolder: "", subMenusHolder: []})
console.log(state)

  const [updateVendorStoreMenu, { loading, error, data }] = useMutation(updateVendorStoreMenuM, {
    onCompleted: (data) =>{
      if(data !== undefined ){
       console.log(data)
        if(data.updateVendorStoreMenu === null){
        }else{
            if(!data.updateVendorStoreMenu.status){
            return (<p> Menus not Updated </p>)
          }
         
          setShow(true)
        
        }

      }

    },
    onError: (error) => console.error("Error", error)
  })





function addSubMenu(e){
console.log(e.target.dataset.id)
let id = e.target.dataset.id
 let check = state.subMenusHolder[id].length == 0 ? 0: 1
  if(check){
   let menu = state.storeMenus
    menu[id].subMenus = menu[id].subMenus.concat(state.subMenusHolder[id])
  	let subMenusHolder = state.subMenusHolder
    subMenusHolder[id] = ""
   
    
  setState(state => {

    return{
      ...state,
      subMenusHolder: subMenusHolder,
      storeMenus: menu
		
    }
  })
  }
}




function addMenu(e){
 let check = state.menuHolder.length == 0 ? 0: 1
  if(check){
  setState(state => {
  let menus = state.storeMenus.concat({
  	menuName: state.menuHolder,
  	subMenus: [],
  	status: true
  })
  let subMenusHolder = state.subMenusHolder.concat("")
    return{
      ...state,
      storeMenus: menus,
      menuHolder: "",
      subMenusHolder: subMenusHolder
    }
  })
  }
}




  const handleInput = e => {
    const inputName = e.currentTarget.name
    const value = e.currentTarget.value
          setState(prev => ({...prev, [inputName]: value}))
  }
  
    const handleSubMenuInput = e => {
    const inputName = e.currentTarget.name
    const value = e.currentTarget.value
    let subMenusHolder = state.subMenusHolder
    subMenusHolder[inputName] = value
          setState(prev => ({...prev, subMenusHolder: subMenusHolder}))
  }
  
  const storeMenus = state.storeMenus.map((value, idx) =>
    <React.Fragment  key={idx}>
   		
          <ListGroup.Item>
          <div className="row">
        	<div className="col-md-6">
          <h6>	<i className="btn-space fa fa-times-circle redText" onClick={e => removeMenu(e)}  aria-hidden="true" data-id={idx} ></i> {value.menuName} </h6>
           </div>
           <div className="col-md-4">
 <Form.Control  size="sm" required type="text" placeholder="SubMenu Name" name={idx} value={state.subMenusHolder[idx]} onChange={handleSubMenuInput} />
    </div>
    <div className="col-md-2">
  <Button className="bottomP" variant="outline-dark" onClick={e => addSubMenu(e)} data-id={idx}><i className=" fa fa-plus-circle" aria-hidden="true" data-id={idx}></i> <span data-id={idx} className="">Add </span></Button>
           
</div>
</div>
 <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-6">
   				{showSubMenus(value.subMenus, idx)}

 				</div>
		
          </ListGroup.Item>
        
    </React.Fragment>

)

function showSubMenus(subMenus, count){
	return subMenus.map((value, idx) =>
    <React.Fragment  key={idx}>
       <div className="col mb-4 bottomP">
           <Badge  variant="success"><h6 className="text-white btn-space">{value}</h6></Badge>
          
           	<i className="btn-space fa fa-times-circle redText" onClick={e => removeSubMenu(e)} aria-hidden="true" data-id={idx}  data-count={count}></i> 
           
          
        </div>
    </React.Fragment>
    )
}


  const storeMenusNav = state.storeMenus.map((value, idx) =>
    <React.Fragment  key={idx}>
   		
     <NavDropdown title={value.menuName} id="basic-nav-dropdown">
     {showSubMenusNav(value.subMenus)}
        </NavDropdown>
    </React.Fragment>

)

function showSubMenusNav(subMenus){
	return subMenus.map((value, idx) =>
    <React.Fragment  key={idx}>
      <NavDropdown.Item href="#">{value}</NavDropdown.Item>
    </React.Fragment>
    )
}
  
function removeMenu(e){
    setState(state => {
        var menus = state.storeMenus
        menus.splice(e.target.dataset.id, 1)
        return {
          ...state,
          storeMenus: menus
        }
      })
}

function removeSubMenu(e){
    setState(state => {
        var menus = state.storeMenus
        menus[e.target.dataset.count].subMenus.splice(e.target.dataset.id, 1)
        return {
          ...state,
          storeMenus: menus
        }
      })
}

 async function handleUpdateStoreMenu() {
 
  
      updateVendorStoreMenu({ variables: {storeMenus: state.storeMenus
      } });
  }
  
  
  return (
    <>

     <div className="row">
      <div className="col-md-8"></div>
        <div className="col-md-4">
     <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header>
              <i className="fas fa-check-circle forestGreen"></i>
            <strong className="mr-auto"> Vendor Tools</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body>Store Menus Successfully Updated</Toast.Body>
        </Toast>
    </div>
    </div>
          
          
          
          <div className="row-fluid     ">


             <Navbar  bg="" expand="lg" variant="light" className=" whiteBG primaryText mx-auto ">
            <Navbar.Brand href="#">

           <img
                     className=""
          src={server + props.data.vendorName}
                  width="100"
                  height="100"
                  className="d-inline-block align-top"
                  alt="logo"
                />
            
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" data-toggle="collapse" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto ">
                {storeMenusNav}
             
              </Nav>
            
              <Nav className="me-auto">
              </Nav>
          <div className=" ">
           <InputGroup   className="">
           <FormControl
           placeholder={"Search Products" }
           aria-label="search input"
           aria-describedby="basic-addon2"
           />
           <Button  variant="outline-dark">Search</Button>
           </InputGroup>
           </div>
          

            
            </Navbar.Collapse>
          </Navbar>



          </div>
          
          
          

<hr/>
  
     <div className="row ">
<div className="col-md-2">
          
       	<span>Add Menu: </span>
</div>

<div className="col-md-4">
 <Form.Control  size="sm" required type="text" placeholder="Menu Name" name="menuHolder" value={state.menuHolder} onChange={handleInput} />
    
</div>
<div className="col-md-2">
  <Button className="bottomP" variant="outline-dark" onClick={e => addMenu(e)}><i className=" fa fa-plus-circle" aria-hidden="true"></i> <span className="">Add </span></Button>     
</div>

</div>

<div className="row-fluid">
          
<div className="">
	<ListGroup className="container">
		{storeMenus}
	</ListGroup>
</div>
</div>
<br/>

<div className="container  text-center">
                
   		
      		
      	
          
          <Button className="btn-space " variant="outline-success" onClick={e => handleUpdateStoreMenu()}><i className="fas fa-arrow-circle-up"></i><span className=""> Update Menu</span></Button>
          
      		
      	
    
      	
          
          </div>

    
    </>

)
}


