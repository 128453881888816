import React, {useState, useEffect} from 'react'
import {useQuery, gql } from '@apollo/client'
import ComponentManager from './components/index'
import Components from './Components'


let components = new Components()
let tags = components.getComponents()
let CM = new ComponentManager()
const initialTagFilterState = {
    tagFilter:    ["s","a"],
    control: 0
}
const currentApp = {app: ["Login"], component: CM.getComponent("Login").app , 
  props: {
  }
}



function AuthenticationPage(props) {
     const [appState , setAppState] = useState(tags)
    const [currentAppState, setCurrentAppState] = useState(currentApp)

    let CM = new ComponentManager()

    const changeAppState = async (name, properties) => {
        
          setCurrentAppState(curentAppState => {
            let component = CM.getComponent(name)
            if(component != false){
              return {
                  app: [component.name],
                   component: component.app,
                   props: properties
              }
            }
            else{
              return {
                ...currentAppState
              }
            }
          })
  }




  function loadAppWithProps(name, properties){
    console.log("Loading with Props")
    changeAppState(name, properties)
  }


  const LoginAppLoad = currentAppState.app.map((name, idy) =>

    <React.Fragment key={name}>

    <currentAppState.component hasAccess={props.hasAccess} tfLogin={props.tfLogin} role={props.role} data={currentAppState.props} requestcomponent={(x,y) => loadAppWithProps(x,y)}/>
    </React.Fragment>
  )


  return (
    <>

    <div className="full">

    {LoginAppLoad}


    </div>

    </>
  );



}

export default AuthenticationPage;

