import React, {useState, useEffect} from 'react'
import {gql, useQuery, useMutation, useLazyQuery} from '@apollo/client'
import {Tab, Nav, Row, Col, ListGroup} from 'react-bootstrap'
import Components from "./Components"
import ComponentManager from './components/index'

let CM = new ComponentManager()
const currentApp = {app: ["Welcome"], component: CM.getComponent("Welcome").app, 
  props: {
  sender: "Welcome"
  }
}



function VendorAppPage(props) {
  console.log(props)
let components = new Components()
let tags = components.getComponents()
  const [appState , setAppState] = useState({tags: tags})
  const [currentAppState, setCurrentAppState] = useState(currentApp)
  console.log(appState)

  const changeAppState = async (name, properties) => {

    let CM = new ComponentManager()
    setCurrentAppState(curentAppState => {
      let component = CM.getComponent(name)
      if(component != false){
        return {
          app: [component.name],
          component: component.app,
          props: properties
        }
      }
      else{
        return {
          ...currentAppState
        }
      }
    })
  }


 const tagsMenu = appState.tags.map((tag, idy) =>
    <React.Fragment key={idy}>
        {
     tag.status ?
     
     <>
     
     
     <li class="nav-item">
     <a class="nav-link active" aria-current="page" href="#" onClick={loadApp} data-id={tag.name} data-idy={idy}>{tag.name}</a>
           </li>

     </>
     :
     <>
     <li class="nav-item">
     <a class="nav-link" href="#" onClick={loadApp} data-id={tag.name} data-idy={idy}>{tag.name}</a>
            </li>
     
     </>
 }
    


  
      </React.Fragment>

  )

  function loadApp(event){
    let tagset = appState
    for(var x=0; x< tagset.tags.length ; x++ ){
      if(tagset.tags[x].status){
        console.log("should remove")
          tagset.tags[x].status = false
      }
    }
    tagset.tags[event.target.dataset.idy].status = true
    console.log(tagset)
   // setAppState(tagset)
      setAppState(state => {

        return {
          ...state,
          tags: tagset.tags
        }
      })
   changeAppState(event.target.dataset.id, currentApp.props)

  }

  function loadAppWithProps(name, properties){
    console.log("Loading with Props")
    changeAppState(name, properties)
  }


  const app = currentAppState.app.map((name, idy) =>

    <React.Fragment key={name}>
    <currentAppState.component  sender="Welcome" user={props.account } partneraccount={props.partneraccount} data={currentAppState.props} requestcomponent={(x,y) => loadAppWithProps(x,y)}/>

    </React.Fragment>
  )


  return (
    <>
      <div className="">
          <nav class="navbar navbar-expand-lg" >
            <div class="container-fluid">
          <a class="navbar-brand" href="#" onClick={(e)=>changeAppState("Welcome", currentApp.props)}>Vendor Tools</a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          {tagsMenu}
               </ul>
                   
               
              </div>
            </div>
          </nav>
          
          </div>
          <br/>
          <div className="container">
          {app}
          </div>
          
   

    </>
  );



}

export default VendorAppPage;






