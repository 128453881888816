class Components {
  components = [

    {
        name: "LoginPage",
        status: false,
        tagFilters: [{name: "Login", status: false}
        ]
    }
]

  getComponents(){
    return this.components
  }
}

export default Components 
