import React, {useState, useEffect} from "react"
import {Card, ListGroup, Button} from 'react-bootstrap'
import {gql, useQuery, useMutation} from '@apollo/client'
const orderProducts = gql`
query getVendorOrderProducts($products: [String!]!){
    getVendorOrderProducts(products: $products){
        status
        code
         ... on ProductsResponse{
            products{
                id
                name
                description
                images
               
                
            }
            
        }
        
    }
  }
`
const updateOrderStatusM = gql`
mutation updateOrderStatus($id: String!, $status: OrderStatus!){
    updateOrderStatus(id: $id, status: $status){
        status
        code
         ... on AcknowledgedResponse{
           data
            
        }
        
    }
  }
`

const server = process.env.REACT_APP_IMAGE_URL
export const VendorOrderPage = props => {
    const [state, setState] = useState(props.data.order.status)
    const [status, setStatus] = useState(props.data.order.status)
    const {loading, error, data} = useQuery(orderProducts, {fetchPolicy: "no-cache", variables: {products: props.data.order.productIds}})
    if(loading){console.log(loading)}
    console.log(state)
    console.log(props)
    
    
    const [updateOrderStatus, { loading: updateLoading, error: updateError, data: updateData }] = useMutation(updateOrderStatusM, {
      onCompleted: (updateData) =>{
        if(data !== undefined ){
          console.log(updateData)
          if(data.updateOrderStatus === null){
          }else{
            if(!updateData.updateOrderStatus.status){
              return (<p> Order not added </p>)
            }
              setState(prev => (status))

          }

        }

      },
      onError: (error) => console.error("Error", error)
    })
    
    function getDate(){
        console.log(parseInt(props.data.order.timeStamp))
        const event = new Date(parseInt(props.data.order.timeStamp));
        
        return event.toString()
    }
    
    const products = props.data.order.productOrder.map((product, idx) =>
    <React.Fragment key={idx}>
                                                       
       <ListGroup.Item className="d-flex  align-items-center">
       

                                                       <img className=" rightSpacer" src={server + getProductImage(product.productId)} width="125px" />
                                                       &nbsp; &nbsp;
          <div className="w-100">
          <span className="mb-0 ">
                                                       <h6>{getProductName(product.productId)} - {getProductDescription(product.productId)}</h6>
                                       
         
         
                                        <h6><small>{product.quantity} X ${parseFloat(product.price).toFixed(2)} </small></h6>
          </span>
          </div>
          <div className="w-100">
          <h6 className="mb-0 text-right f400">${(product.price.toFixed(2) * product.quantity).toFixed(2)}</h6>
          </div>

          </ListGroup.Item>

                                                       
        </React.Fragment>
    )
    function getProductName(id){
        if(data?.getVendorOrderProducts?.status){
            for(var product of data.getVendorOrderProducts.products){
                if(id === product.id){
                    return product.name
                }
            }
        }
    }
    function getProductDescription(id){
        if(data?.getVendorOrderProducts?.status){
            for(var product of data.getVendorOrderProducts.products){
                if(id === product.id){
                    return product.description
                }
            }
        }
    }
    
    function getProductImage(id){
        if(data?.getVendorOrderProducts?.status){
            for(var product of data.getVendorOrderProducts.products){
                if(id === product.id){
                    return product.images[0]
                }
            }
        }

    }
    
    function getAddress(){
        if(props.data.order.address != null){
            return props.data.order.address.streetNo +" " + props.data.order.address.streetName + ", " + props.data.order.address.municipality + ", " + props.data.order.address.district
        }
        
    }
    
    function getDetails(){
        var subtotal = 0
        var total  = 0
        var delivery = 0
        for(var product of props.data.order.productOrder){
            let productSubtotal = 0
            productSubtotal = product.price * product.quantity
            subtotal = subtotal + productSubtotal
            console.log( product)
        }
        if(props.data.order.address != null){
            delivery = 5
        }
        total = subtotal+ delivery
        return(<React.Fragment>
             
            <ListGroup.Item className="d-flex  align-items-center">
           
            
            <div className="w-100">
            <span className="mb-0 ">
            <h6>Subtotal</h6>
            </span>
            </div>
            <div className="w-100">
            <h6 className="mb-0 text-right f400">${subtotal}</h6>
            </div>
            </ListGroup.Item>
               
               {props.data.order.address != null ?
            <>
            
            <ListGroup.Item className="d-flex  align-items-center">
            
            
            <div className="w-100">
            <span className="mb-0 ">
            <h6>Delivery</h6>
            </span>
            </div>
            <div className="w-100">
            <h6 className="mb-0 text-right f400">$5.00</h6>
            </div>
            </ListGroup.Item>
            </>
                :
            <>
            </>
            
        }
            
            
               <ListGroup.Item className="d-flex  align-items-center">
              
               
               <div className="w-100">
               <span className="mb-0 ">
               <h6>Total</h6>
               </span>
               </div>
               <div className="w-100">
               <h6 className="mb-0 text-right f400">${total}</h6>
               </div>
               </ListGroup.Item>
               
               <h6 className="mb-0 text-right f400"> </h6>
             
             
               </React.Fragment>
               )
    }
    function statusChange(e){
        console.log(e)
        console.log("status")
        setStatus(prev => ( e.target.value))
    }
    
    function updateStatus(){
        updateOrderStatus(
          {
          variables: {
              id: props.data.order.id,
              status: status
          }
          }
          )
    }
    
    function goBack(event){
      props.requestcomponent(props.data.sender, { requestcomponent: props.requestcomponent, sender: props.data.previous})
      
    }
    
    const statuses = ["PLACED", "ACCEPTED", "SHIPPED", "COMPLETED"]
    

    function getSelected(name){
        
        if(name === props.data.order.status){
            return "selected"
        }
        
    }
    const statusList = statuses.map((status, idx) =>
        <React.Fragment key={idx}>
                                    {
                        props.data.order.status === status ?
        <option value={status} selected>{status}</option> :
        
        <option value={status}> {status}</option>
        
    }
                                    
                                    
            </React.Fragment>
    )
    
    function showStatus(){
        
        
        return(
               <React.Fragment>
               <select id="status" onChange={statusChange}>
               {statusList}
               </select>
               </React.Fragment>
               )
    }
  return (
    <>
          <Card.Header className="">
               <div className="row ">
                 <div className="col-md-4">
                 <a href="#" className="forestGreen text-start "> <span onClick={e => goBack(e)} ><i className="fas fa-arrow-left"></i> Back </span> </a>
                  </div>
                    <div className="col-md-4">
          <h5 className=" text-center darkSlateGray  ">Order - {props.data.order.lookupCode}</h5>
          
                </div>
                  <div className="col-md-4">
                  </div>
                </div>
                </Card.Header>
             
               
               <hr/>
          <p>Date Ordered: {getDate()}</p>
          <h6>Delivery | Pick Up Status: {props.data.order.address != null ? <span>Delivery to   {getAddress()}</span> :  <span>PickUp</span>}</h6>
          <Card.Header className="">
               <div className="row ">
                 <div className="col-md-4">
          <h6 className=" darkSlateGray  ">Products Ordered</h6>
                  </div>
                    <div className="col-md-4">
          <h5 className=" text-center darkSlateGray  "></h5>
          
                </div>
                  <div className="col-md-4">
          <h6 className=" darkSlateGray ">   Product Status : {state}</h6>
          <div className="container row">
          <div className="col-md-6">
          {showStatus()}
         
          </div>
          
          <div className="col-md-6">
          <Button className="bottomP" variant="outline-dark" size='sm' onClick={updateStatus}> <span className="">Update Status</span></Button>
          </div>
         
          
          </div>
                  </div>
                </div>
                </Card.Header>
          <div className="row">
         
          <ListGroup variant="flush" className="d-flex    ">
          {products}
          </ListGroup>
          <hr/>
          
          {getDetails()}
          </div>
          <hr/>
          <div className="row">
          
    
          </div>
        
   
    </>
  );


};



