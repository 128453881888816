import React, {useRef, useState, useEffect} from "react";
import auth from "../auth";
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {gql, useMutation} from '@apollo/client'
import roleManager from '../RoleManager'
import { useHistory } from "react-router-dom";
import logo from '../img/everyday.png'
const  loginM = gql `
    mutation login($email: String!, $password: String!){
      login(email: $email, password: $password){
        token
        tokenExpiration
        user{
          id
          firstName
          lastName
          role{
            role
            id
          }
          twoFactorAuth{
            status
          }
        }

      }
    }
  `
const initialState = {
  email: '',
  password: ''
}

export const LoginPage = props => {
  
  let history = useHistory()


  useEffect(() => {
      if(props.hasAccess){
    loadAccess({
      role: props.role
    })
  }
  if(props.tfLogin){
    loadTwoFactorLogin()
  }

    
  });

  const [state, setState] = useState(initialState)
  const [errors, setErrors] = useState('')

  const [login, { loading, error, data }] = useMutation(loginM, {
    onCompleted: (data) =>{
      if(data !== undefined ){
       console.log(data)
      if(data.login === null){
      }else{
        let authPayload = {
          token: data.login.token,
          tokenExpiration: data.login.tokenExpiration,
          firstName: data.login.user.firstName,
          lastName: data.login.user.lastName
        }
        auth.setAuth(authPayload)   
        if(data.login.user.twoFactorAuth.status){
         loadTwoFactorLogin()
        }
        else if(data.login.user.role.id.length < 1){
         loadAccess(data.login.user.role)
         
        }
       else{
        history.push(roleManager.getStartingRoute(data.login.user.role.role)) 
       }
        
      }
  }
    },
    onError: (error) => console.error("Error", error)
  })

  if(loading){return (<p> Loading... </p>)}

 const handleSubmit = e => {
    e.preventDefault()

    for(let key in state){
      if(state[key] === ''){
        setErrors(`You must provide the input ${key}`)
        console.log(errors)
        return
      }
    
    }
    setErrors('')
   console.log("before login")

    handleLogin()
  }

  const handleInput = e => {
    if(e.keyCode === 13){
      handleSubmit(e)
    }
    
    const inputName = e.currentTarget.name
    const value = e.currentTarget.value
    setState(prev => ({...prev, [inputName]: value}))
  }
 



  async function handleLogin() {
    console.log("in query")
     login({ variables: { email: state.email, password: state.password } });

  }


  function loadTwoFactorLogin(){
    props.requestcomponent("Two Factor Login", { requestcomponent: props.requestcomponent})
  }

  function loadAccess(role){
    props.requestcomponent("Account Setup", { role: role, requestcomponent: props.requestcomponent})
  }
    
    function loadRegister(e){
        e.preventDefault()
        history.push("/register");
    }
 
  return (
    <>
    
 <div className=" jumbotron mx-4">
      <br/>
    <div className="jumbotron container ">
      
    { errors && ( <p>{errors}</p>)
    }
          <div className=" text-center">
          <img src={logo} className="img-fluid"   alt="logo" width="1080px" height="500px" />
        </div>
          <br/>
          <br/>
          <div className="mx-4">
      <form noValidate>
        <Form.Group controlId="v">
          
          <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          <Form.Control  size="sm" required type="email" placeholder="E-mail" name="email" value={state.email} onChange={handleInput} onKeyDown={handleInput}/>
          <br/>
          <Form.Control size="sm" type="password"  placeholder="Password" name="password" value={state.password} onChange={handleInput} onKeyDown={handleInput} />
          </Form.Group>
          <div className="row-fluid text-center">
          <br/>
          <Button size="md" variant="outline-primary" onClick={e => handleSubmit(e)} >Login</Button>
          <br/>
          <br/>
          <a href="#" onClick={(e)=>loadRegister(e)}><small >Don't have an account? Register <i className="bi bi-list-task"></i></small> </a>
          </div>
          
      </form>
       </div>
    </div>
          
    </div>
          
          
          
    </>
  );
};

