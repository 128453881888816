import React, {useEffect, useState} from "react";
import { Route, Redirect } from "react-router-dom";
import {gql, useQuery} from '@apollo/client'
import { useLazyQuery } from '@apollo/client';
import { useHistory } from "react-router-dom";
import {NoAccessPage} from "./noaccess"
import AccountPage from "./account/account"
import Auth from "./auth"
import {VerifyEmailPage} from "./account/components/verifyEmail"
import roleManager from './RoleManager'
const isUserQ = gql`
query isUser{
  isUser{
      user{
        firstName
        lastName
        emailVerified
        email
        role{
          role
          id
        }
        twoFactorAuth{
        status
        }
      }
      tokenType
    }
  }
`


export const AccountContainer = ({
  component: Component,
  ...rest
}) => {

  let history = useHistory()
  const [isUser, {loading, error, data}] = useLazyQuery(isUserQ)
 

  useEffect(() => {
    if(Auth.hasActiveToken()){
      isUser()
    }else if(Auth.hasExpiredToken()){
      Auth.logout()
    }
    
  }, [isUser]);

  if(loading){return (<p> Loading... </p>)}

  if(!Auth.hasToken()){
    return(
      <>
     <Redirect to={"/login"} />
      </>
    )
  }
  if ((data?.isUser.user.role.id.length > 0 )) {
    console.log(data.isUser.user)
    if(Auth.hasAccess(data.isUser.user.twoFactorAuth, data.isUser.tokenType)){

      let account = {role: data.isUser.user.role.role, emailVerified: data.isUser.user.emailVerified, email: data.isUser.user.email}  
      if(data.isUser.user.emailVerified){
        return (
          <Route
          {...rest}
          render={props => {
            return (
              <div>
              <AccountPage account= {data.isUser.user} />
              </div>
            );
          }}
          />
        );

      }
      else{

        return (
          <Route
          {...rest}
          render={props => {
            return (
              <div>
              <VerifyEmailPage user={account} />
              </div>
            );
          }}
          />
        );




      }

    }
  }
  if ((data?.isUser.user.role.id.length == 0 )) {
    console.log(data)
  return(
    <>
     <Redirect to={"login"} />
    </>
    )
  }





  return(
    <>
    <NoAccessPage /> 
    </>
  )

};


