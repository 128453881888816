import Auth from  "./auth"

class ErrorHandler {
  constructor(){

  }

  handleError(errorMessage, errorLocation, errorPath){
   console.log(errorMessage)
    if(errorMessage === "Not allowed"){

    console.log("taking to logout due to error")
      Auth.logout()
    }
  }
}

export default new ErrorHandler();
