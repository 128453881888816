import {Card, Badge, Col, Button, Form, Toast} from 'react-bootstrap'
import React, {useRef, useState, useEffect, useMemo} from "react";
import {gql, useMutation, useQuery} from '@apollo/client'


const profile = gql`
{
  getVendorProfile{
    status
	code
    ... on ProfileResponse{
        profile{
            name
            profileSlogan
            profileImage
            storeImageBanner
            accessCode
            address{
            	streetNo
            	streetName
            	district
            	municipality
            }
        
        }
    }
  }
}
`

const  updateVendorProfileM = gql `
    mutation updateVendorProfile($updatedProfile: UpdatedProfileInput!){
      updateVendorProfile(updatedProfile: $updatedProfile){
        status
        code
        ... on AcknowledgedResponse{
        	data
      	}
      }
    }
  `


const districts = [
  "ORANGEWALK", "COROZAL", "BELIZE","CAYO", "STANNCREEK", "TOLEDO"

]

const municipalities = ["Orange Walk Town", "Belize City", "Corozal Town", "San Ignacio", "Santa Elena",
  "Belmopan City", "San Pedro Town", "Caye Caulker", "Ladyville", "Dangriga", "Punta Gorda"]


const districtMatch = {
  "Orange Walk Town" : "ORANGEWALK",
  "Belize City" : "BELIZE",
  "Corozal Town" : "COROZAL",
  "San Ignacio" : "CAYO",
  "Santa Elena" : "CAYO",
  "Belmopan City" : "CAYO",
  "San Pedro Town" : "BELIZE",
  "Caye Caulker" : "BELIZE",
  "Ladyville" : "BELIZE",
  "Dangriga" : "STANNCREEK",
  "Punta Gorda" : "TOLEDO"

}

const districtMatchControl = {
  "Orange Walk" : "ORANGEWALK",
  "Belize" : "BELIZE",
  "Corozal" : "COROZAL",
  "Cayo" : "CAYO",
  "Belize" : "BELIZE",
  "Stann Creek" : "STANNCREEK",
  "Toledo" : "TOLEDO"

}

const municipalityMatch = {
  "Orange Walk Town" : "ORANGEWALKTOWN",
  "Belize City" : "BELIZECITY",
  "Corozal Town" : "COROZALTOWN",
  "San Ignacio" : "SANIGNACIO",
  "Santa Elena" : "SANTAELENA",
  "Belmopan City" : "BELMOPANCITY",
  "San Pedro Town" : "SANPEDROTOWN",
  "Caye Caulker" : "CAYECAULKER",
  "Ladyville" : "LADYVILLE",
  "Dangriga" : "DANGRIGA",
  "Punta Gorda" : "PUNTAGORDA"

}


const server = process.env.REACT_APP_IMAGE_URL
const hostingServer = process.env.REACT_APP_SELF_URL

export const VendorProfilePage = props => {
const {loading, error, data} = useQuery(profile, {fetchPolicy: "no-cache"})

function goBack(event){
  props.requestcomponent(props.data.sender, { requestcomponent: props.requestcomponent})
  
}

  return (
    <>
      {
     loading && 
<div id="overlay">

<div className="loader"></div>
    </div>

    }
          <div className="row-fluid bottomP ">
           <Card.Header className="">
           <div className="row ">
             <div className="col-md-4">
             <a href="#" className="forestGreen text-start "> <span onClick={e => goBack(e)} ><i className="fas fa-arrow-left"></i> Back </span> </a>
          	</div>
          	  <div className="col-md-4">
            <h5 className=" text-center darkSlateGray">Vendor Profile</h5>
            </div>
              <div className="col-md-4">
              </div>
            </div>
            </Card.Header>
          </div>
          <hr />
    
          {
          		!loading ?
              <>
          <div className="container fontLato">
   				<VendorProfileDataPage data={data?.getVendorProfile.profile}/>
          </div>
          </>
          :
          <p></p>
          }

    </>
  )


};




export const VendorProfileDataPage = props => {

const [show, setShow] = useState(false);
const [state, setState] = useState({edit: false, newProfileImage: "", newStoreImageBanner: "",
	profileImageCount:0, storeImageBannerCount:0, profileImageHolder: "", storeImageBannerHolder: "",
	...props.data})
console.log(state)

  const [updateVendorProfile, { loading, error, data }] = useMutation(updateVendorProfileM, {
    onCompleted: (data) =>{
      if(data !== undefined ){
       console.log(data)
        if(data.updateVendorProfile === null){
        }else{
            if(!data.updateVendorProfile.status){
            return (<p> Profile not Updated </p>)
          }
          setEdit()
          setShow(true)
        
        }

      }

    },
    onError: (error) => console.error("Error", error)
  })


function loadImage(e){
    var reader = new FileReader();
     reader.onload = function(){
        addStoreBannerImage(e, reader.result)
    };
     reader.readAsDataURL(e.target.files[0]); 
  }

  function addStoreBannerImage(e, image){
    e.preventDefault()
    setState(state => {
    
    if(state.storeImageBannerCount > 0){
      return {
        ...state,
        newStoreImageBanner: image,
        storeImageBanner: ""
      }
    }
      
      return {
        ...state,
        newStoreImageBanner: image,
        storeImageBanner: "",
        storeImageBannerHolder: state.storeImageBanner,
        storeImageBannerCount: 1
      }
    })
}

function loadProfileImage(e){
    var reader = new FileReader();
     reader.onload = function(){
        addProfileImage(e, reader.result)
    };
     reader.readAsDataURL(e.target.files[0]); 
  }

  function addProfileImage(e, image){
    e.preventDefault()
    setState(state => {
    
    if(state.profileImageCount > 0){
    	   return {
        ...state,
        newProfileImage: image,
        profileImage: ""
      }
    }
      
      return {
        ...state,
        newProfileImage: image,
        profileImage: "",
        profileImageHolder: state.profileImage,
        profileImageCount: 1
      }
    })
}


  function removeNewStoreImageBanner(e){
    e.preventDefault()
        document.getElementById("storeImageBanner").value = null 
    setState(state => {

      
      return {
        ...state,
     	newStoreImageBanner: "",
        storeImageBanner: state.storeImageBannerHolder,
        storeImageBannerHolder: "",
        storeImageBannerCount: 0
      }
    })
}

  function removeNewProfileImage(e){
    e.preventDefault()
        document.getElementById("profileImage").value = null 
    setState(state => {

      
      return {
        ...state,
        newProfileImage: "",
        profileImage: state.profileImageHolder,
        profileImageHolder: "",
        profileImageCount: 0
      }
    })
}


function setEdit(e){
  setState(state => {
    return{
      ...state,
      edit: !state.edit
    }
  })
}

  const handleInput = e => {
    const inputName = e.currentTarget.name
    const value = e.currentTarget.value
          setState(prev => ({...prev, [inputName]: value}))
  }
  
    const handleAddressInput = e => {
    const inputName = e.currentTarget.name
    const value = e.currentTarget.value
      setState(state => {
    
      return {
        ...state,
        address: {
        	...state.address,
        	[inputName]: value
        }
      }
    })
  }


    const handleMunicipalityInput = e => {
    const inputName = e.currentTarget.name
    const value = e.currentTarget.value
      setState(state => {
    
      return {
        ...state,
        address: {
        	...state.address,
        	municipality: municipalityMatch[value],
        	district: districtMatch[value]
        }
      }
    })
  }
    
    const municipalityTypes = municipalities.map((municipality, idx) => 
    <option key={idx}>{municipality}</option>
  )
  
  function getObjectKey(obj, value) {
  return Object.keys(obj).find((key) => obj[key] === value);
}

  async function handleUpdateProfile() {
 
  
      updateVendorProfile({ variables: {updatedProfile: {
          name: state.name,
     	  address: {
     	  	streetNo: parseInt(state.address.streetNo),
     	  	streetName: state.address.streetName,
     	  	municipality: state.address.municipality,
     	  	district: state.address.district
     	  },
     	  newProfileImage: state.newProfileImage,
     	  newStoreImageBanner: state.newStoreImageBanner,
     	  profileSlogan: state.profileSlogan,
     	  profileImage: (state.profileImageCount > 0) ? state.profileImageHolder : state.profileImage,
     	  storeImageBanner: (state.storeImageBannerCount > 0) ? state.storeImageBannerHolder : state.storeImageBanner
         

      }} });
  }
  
  return (
    <>
    {       loading && 
<div id="overlay">

<div className="loader"></div>
    </div>

    }
  
     <div className="row">
      <div className="col-md-8"></div>
        <div className="col-md-4">
     <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide>
          <Toast.Header>
              <i className="fas fa-check-circle forestGreen"></i>
            <strong className="mr-auto"> Vendor E-Commerce Tools</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body>Profile Successfully Updated</Toast.Body>
        </Toast>
    </div>
    </div>
  <div className="row-fluid bottomP">
   Store Website: <a href={hostingServer + "store/" + props.data.accessCode.toLowerCase() } > { hostingServer + "store/" +  props.data.accessCode.toLowerCase() } </a>
    <p>Open in a Different Web Browser when logged in to you Vendor Account</p>
    </div>


        <div className="row bottomP">
          <div className="col-md-6">
          <h5 className="">Vendor Name: {state.name}</h5>
          </div>
          { state.edit &&
          <div className="col-md-6">
          <Form.Control  size="sm" required type="text" placeholder="Name" name="name" value={state.name} onChange={handleInput} />
         </div>
         }
         </div>
         
            <div className="row bottomP">
          <div className="col-md-6">
          <h5 className="">Profile Slogan : {state.profileSlogan}</h5> 
          </div>
          { state.edit &&
          <div className="col-md-6">
          <Form.Control  size="sm" required type="text" placeholder="Profile Slogan" name="profileSlogan" value={state.profileSlogan} onChange={handleInput} />
         </div>
         }
         </div>
         
         
          <div className="row bottomP ">
          <div className="col-md-6">
          <h5 className="">Address: </h5>
          <p>{state.address.streetNo} {state.address.streetName}</p>
          <p>{getObjectKey(municipalityMatch, state.address.municipality)}, {getObjectKey(districtMatchControl, state.address.district)}</p>
          </div>
          
          { state.edit &&
          <>
           <div className="col-md-6">
              <div className="row  ">
          <div className="col-md-4">
          <Form.Control  size="sm" required type="number" placeholder="Street No" name="streetNo" value={state.address.streetNo} onChange={handleAddressInput} />
         </div>
            <div className="col-md-8">
          <Form.Control  size="sm" required type="text" placeholder="Street Name" name="streetName" value={state.address.streetName} onChange={handleAddressInput} />
         </div>
		</div>
		    <div className="row ">
       
    
          <div className="col-md-12">
                <Form.Label>Municipality</Form.Label>
    			<Form.Control as="select" name="municipality" value={getObjectKey(municipalityMatch, state.address.municipality)} onChange={handleMunicipalityInput}>
    			<option hidden  >Select...</option>
    			{municipalityTypes}
    		</Form.Control>
         </div>
         </div>
		</div>
       
     
    	
         </>
         }
           
         </div>
    
         
           <div className="row bottomP">
          <div className="col-md-6">
          <h5 className="">Profile Image</h5>
          <h6>(Recommended Size: 300px X 300px)</h6>
          {	state.profileImage &&
        	<img  width="40%" src={server + state.profileImage} className="topP" />
          }
          
           {	state.newProfileImage &&
        	<>
        	<img width="40%" src={state.newProfileImage} className="topP" />
        	    { state.edit &&
        	<i className="btn-space fa fa-times-circle redText" aria-hidden="true" onClick={e => removeNewProfileImage(e)} ></i> 
        	}
      		</>
          }
          </div>
          { state.edit &&
          <div className="col-md-6">
         <Form.Control className="bottomP" id="profileImage" type="file" size="sm" onChange={e => loadProfileImage(e)}/>
 			 </div>
         }
         </div>
    
         
              <div className="row bottomP">
          <div className="col-md-6">
          <h5 className="">Store Image Banner</h5>
          <h6>(Recommended Size: 1000px X 300px)</h6>
          {	state.storeImageBanner &&
        	<img width="100%" src={server + state.storeImageBanner} className="topP" />
          }
          
           {	state.newStoreImageBanner &&
           <>
        	<img width="90%" src={state.newStoreImageBanner} className="topP" />
        	    { state.edit &&
        	<i className="btn-space fa fa-times-circle redText" aria-hidden="true" onClick={e => removeNewStoreImageBanner(e)} ></i> 
        	}
          </>
          }
          </div>
          { state.edit &&
          <div className="col-md-6">
         <Form.Control className="bottomP" id="storeImageBanner" type="file" size="sm" onChange={e => loadImage(e)}/>
 			 </div>
         }
         </div>
    
   
                <div className="row container  topP">
          
          <div className="col-md-6">
          <Button className="btn-space text-center" variant="outline-dark" onClick={e => setEdit()}><i className="fas fa-pencil-alt"></i><span className=""> Edit Profile</span></Button>
      		</div>
      			<div className="col-md-6">
      		{
     	state.edit && 
          
          <Button className="btn-space" variant="outline-success" onClick={e => handleUpdateProfile()}><i className="fas fa-arrow-circle-up"></i><span className=""> Update Profile</span></Button>
      		
      	}
      	</div>
      		</div>
      
    
    </>

)
}


