class Auth {
  constructor() {
  }
  
  setAuth(authPayload){
    console.log(authPayload)
    localStorage.setItem('token', authPayload.token)
    localStorage.setItem('tokenExpiration', authPayload.tokenExpiration)
    localStorage.setItem('firstName', authPayload.firstName)
    localStorage.setItem('lastName', authPayload.lastName)
    var event = new Event("loggedIn")
    document.dispatchEvent(event)
  }

  removeAuth(cb){
    localStorage.clear()
var clearNetworkCacheEvent = new Event ("clearCache")
    document.dispatchEvent(clearNetworkCacheEvent)
    cb()
  }
  
  removeAuthData(){
    localStorage.clear()
    

    var clearNavbarEvent = new Event ("clearNavbarData")
    document.dispatchEvent(clearNavbarEvent)

  }

  logout(){
    var logoutEvent = new Event ("logout")
    document.dispatchEvent(logoutEvent)
  }

  getToken(){
    return localStorage.getItem('token') 
  }

  getName(){

    let name = localStorage.getItem('firstName') !== null ? localStorage.getItem('firstName').concat(" ").concat(localStorage.getItem('lastName')) : " "
    return name 
  }


  hasActiveToken(){
    let tokenExpiration = localStorage.getItem('tokenExpiration') !== null ? parseInt(localStorage.getItem('tokenExpiration')) *1000 : 0
    let now = Date.now()
    if (localStorage.getItem('token') !== null && (now < tokenExpiration)) {
      return true
    }
    return false

  }

  hasExpiredToken(){
    let now = Date.now()
    let tokenExpiration = localStorage.getItem('tokenExpiration') !== null ? parseInt(localStorage.getItem('tokenExpiration')) *1000 : 0
    if(now > tokenExpiration && this.hasToken()){  
      return true
    }
    return false
  }

  hasAccess(twoFactorAuth, tokenType){
    if((twoFactorAuth.status && tokenType) || !twoFactorAuth.status){
      return true
    }
    return false

  }

  hasToken(){
    if(localStorage.getItem('token') !== null){
      return true
    }
    return false
  }
}




export default new Auth();

