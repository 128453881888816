
import { TwoFactorLoginPage } from './tfLogin'
import { LoginPage } from './login'
import { AccountSetup } from './accountsetup'

const apps = [
    {
        name: 'Login',
        app: LoginPage
    },
    {
      name: 'Two Factor Login',
      app: TwoFactorLoginPage
  },
  {
    name: 'Account Setup',
    app: AccountSetup
  }
    
]

class ComponentManager {
  constructor(){

  }

  getComponent(name){

      for(var app of apps){
            if(app.name.valueOf() === name.valueOf()){
                return  app
            }
        }
    return false

  }
}

export default ComponentManager
