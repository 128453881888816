import React from "react";
import { Route, Redirect } from "react-router-dom";

export const EmailVerificationContainer = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
    {...rest}
    render={props => {
        return( 
          <div>
          <Component {...props} />
          </div>
        );
           }}
    />
  );
};

