import React, {useState, useEffect} from "react"
import {Card, ListGroup, Button} from 'react-bootstrap'
import {gql, useQuery} from '@apollo/client'
import { useHistory } from "react-router-dom";
const orderProducts = gql`
query getVendorOrderProducts($products: [String!]!){
    getVendorOrderProducts(products: $products){
        status
        code
         ... on ProductsResponse{
            products{
                id
                name
                description
                images
               
                
            }
            
        }
        
    }
  }
`
const server = process.env.REACT_APP_IMAGE_URL
export const CustomerOrderPage = props => {
    let history = useHistory()
    console.log(props)
        const {loading, error, data} = useQuery(orderProducts, {fetchPolicy: "no-cache", variables: {products: props.data.order.order.productIds}})
        if(loading){console.log(loading)}
        console.log(data)
    function goBack(event){
      props.requestcomponent(props.data.sender, { requestcomponent: props.requestcomponent, sender: props.data.previous})
      
    }
    
    function getDate(){
        console.log(parseInt(props.data.order.order.timeStamp))
        const event = new Date(parseInt(props.data.order.order.timeStamp));
        
        return event.toString()
    }
    
    const products = props.data.order.order.productOrder.map((product, idx) =>
    <React.Fragment key={idx}>
                                                             
       <ListGroup.Item className="d-flex  ">
       
                                                             <a href="#"   data-id= {product.productId} onClick={(e) => loadProduct(e)}>
                                                             <img className=" rightSpacer" data-id= {product.productId} src={server + getProductImage(product.productId)} width="125px" />  </a>
                                                       &nbsp; &nbsp;
          <div className="w-100">
          <span className="mb-0 ">
                                                       <h6>{getProductName(product.productId)}</h6>
                                                          
                                                             <p>{getProductDescription(product.productId)}</p>
         
         
                                        <h6><small>{product.quantity} X ${parseFloat(product.price).toFixed(2)} </small></h6>
          </span>
          </div>
          <div className="w-100">
          <h6 className="mb-0 text-center">${(product.price.toFixed(2) * product.quantity).toFixed(2)}</h6>
          </div>
            
          </ListGroup.Item>
                                                           
             <hr/>
        </React.Fragment>
    )
    
    function getProductName(id){
        if(data?.getVendorOrderProducts?.status){
            for(var product of data.getVendorOrderProducts.products){
                if(id === product.id){
                    return product.name
                }
            }
        }
    }
    function getProductDescription(id){
        if(data?.getVendorOrderProducts?.status){
            for(var product of data.getVendorOrderProducts.products){
                if(id === product.id){
                    return product.description
                }
            }
        }
    }
    
    function getProductImage(id){
        if(data?.getVendorOrderProducts?.status){
            for(var product of data.getVendorOrderProducts.products){
                if(id === product.id){
                    return product.images[0]
                }
            }
        }

    }
    
    
    function getDetails(){
        var subtotal = 0
        var total  = 0
        var delivery = 0
        for(var product of props.data.order.order.productOrder){
            let productSubtotal = 0
            productSubtotal = product.price * product.quantity
            subtotal = subtotal + productSubtotal
            console.log( product)
        }
        if(props.data.order.order.address != null){
            delivery = 5
        }
        total = subtotal+ delivery
        return(<React.Fragment>
             
            <ListGroup.Item className="d-flex  align-items-center">
           
            
            <div className="w-100">
            <span className="mb-0 ">
            <h6>Subtotal</h6>
            </span>
            </div>
            <div className="w-100">
            <h6 className="mb-0 text-right f400">${parseFloat(subtotal).toFixed(2)}</h6>
            </div>
            </ListGroup.Item>
               
               {props.data.order.order.address != null ?
            <>
            
            <ListGroup.Item className="d-flex  align-items-center">
            
            
            <div className="w-100">
            <span className="mb-0 ">
            <h6>Delivery</h6>
            </span>
            </div>
            <div className="w-100">
            <h6 className="mb-0 text-right f400">$5.00</h6>
            </div>
            </ListGroup.Item>
            </>
                :
            <>
            
            </>
            
        }
            
            
               <ListGroup.Item className="d-flex  align-items-center">
              
               
               <div className="w-100">
               <span className="mb-0 ">
               <h6>Total</h6>
               </span>
               </div>
               <div className="w-100">
               <h6 className="mb-0 text-right f400">${parseFloat(total).toFixed(2)}</h6>
               </div>
               </ListGroup.Item>
               
               <h6 className="mb-0 text-right f400"> </h6>
             
             
               </React.Fragment>
               )
    }
    
    function loadVendor(e){
        e.preventDefault()
        console.log(e)
      history.push("/store/" + props.data.order.vendorStore);
    }
    
    function loadProduct(e){
        e.preventDefault()
        console.log(e.target.dataset.id)
      history.push("/product/" + e.target.dataset.id);
    }
    
    function getType(){
        if(props.data.order.order.address != null){
            return(
                   <React.Fragment>
                   <h6>Delivery </h6>
                   <p>{props.data.order.order.address.streetNo + " " + props.data.order.order.address.streetName + ","}</p>
                   <p>{props.data.order.order.address.municipality + ", " + props.data.order.order.address.district}</p>
                   </React.Fragment>
                   )
        }else{
            return(
                   <React.Fragment>
                   <h6>Pickup at {props.data.order.vendor} </h6>
                   <p>{props.data.order.vendorAddress.streetNo + " " + props.data.order.vendorAddress.streetName + ","}</p>
                   <p>{props.data.order.vendorAddress.municipality + ", " + props.data.order.vendorAddress.district}</p>
                   </React.Fragment>
                   )
        }
    }
      
    
  return (
    <>
          <br/>
          <Card.Header className="">
                <div className="row ">
                  <div className="col-md-4">
                  <a href="#" className="forestGreen text-start "> <span onClick={e => goBack(e)} ><i className="fas fa-arrow-left"></i> Back </span> </a>
                   </div>
                     <div className="col-md-4">
                 <h5 className=" text-center darkSlateGray  ">Order</h5>
                 </div>
                   <div className="col-md-4">
                   </div>
                 </div>
                 </Card.Header>
          <hr />
          <div className="row">
          <div className="col-md-8">
           <h6 className=" f400">Sold By:<a href="#"    onClick={(e) => loadVendor(e)}> {props.data.order.vendor}</a> </h6>
          {products}
          <div className="container"><br/>{getDetails()}</div>
          </div>
          
          <div className="col-md-4">
          Order Status: {props.data.order.order.status}
          <hr/>
          {getType()}
          </div>
          </div>
    </>
  );


};


