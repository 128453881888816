import React, { useState } from "react";
import Image, { MyImage } from "./MyImage";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";

const server = process.env.REACT_APP_IMAGE_URL
export default function({ scrollPosition, ...props }) {
   let history = useHistory()


function loadProduct(e){
    e.preventDefault()
    console.log(e)
  history.push("/product/" + e.target.dataset.id);
}

  let [state, setstate] = useState(0);

  return (
      <>
        <h6 className=""> {props.name}</h6>
    <div className="slide-item bottomP">
       <a href="#"  data-id= {props.id} >
      <img
        onClick={(e) => loadProduct(e)}
        data-id={props.id}
        src={server + props.download_url}
        alt="productimage"
        style={{
         
          objectFit: "fill",
          backgroundPosition: "center",
          WebkitTransformOrigin: "center"
        }}
        height={"120px"}
      />
      </a>
    
    
    </div>
      </>
  );
}
