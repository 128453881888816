import { Button} from 'react-bootstrap'
import {gql, useMutation} from '@apollo/client'
import React, {useRef, useState} from "react";
const sendVerificationM = gql`
  mutation SendEmailVerification{
    SendEmailVerification{
      status
      code
      ... on AcknowledgedResponse{
          data
      }
    }
  }
`
export const VerifyEmailPage = props => {
  console.log(props)

  const [state, setState] = useState(false)


  const [sendemailverification, { loading, error, data }] = useMutation(sendVerificationM, {
    onCompleted: (data) =>{
      if(data !== undefined ){
       console.log(data)
      if(data.SendEmailVerification === null){
      }else{
          if(data.SendEmailVerification.status){
            setState(true) 
          }
      }
  }
    },
    onError: (error) => console.error("Error", error)
  })



  return (
    <>
    {
      loading && 
      <div id="overlay">

      <div className="loader"></div>
      </div>

    }
    <div className="topP"> </div>
    <div className="fancyH ">
  <h6 className="text-center  blueText">Verify Email</h6>
    </div>
  <div className="row-fluid text-center">
  </div>
<div className="jumbotron blueText topP">
    <div className="row-fluid text-center">
  


    {

          props.user.emailVerified ?

<h6> E-mail is Already Verified</h6>
      :
    
<>
    <h6> Send E-mail Verification Link To {props.user.email}</h6>
    <Button variant="outline-success" onClick={sendemailverification}>Send Verification Email</Button>{' '}

</>
    }
    </div>
</div>
    {
      state ?
      <>
       <div className="jumbotron topP   text-center">
      <h3 className="text-center"><i className="fas fa-paper-plane"></i> Email Link Succesfully Sent
      </h3>
    <h6 className="navLightText">Link Should Arrive Shorty</h6>
        <p></p>
        <div className="jumbotron topP">
        <hr></hr>
    <h3>Verification Link Below</h3>
    <a href={data?.SendEmailVerification.data}>Verify Email</a>
      </div>
      </div>
</>
:
<p></p>
    }


    </>
  );


};


