import React, {useEffect} from "react";

const roleSetup = {
      PATIENT: "PATIENTSETUP",
      DOCTOR: "DOCTORSETUP",
      PHARMACY: "PHARMACYSETUP"
}
export const AccountSetup = props => {
  useEffect(() => {
    loadRoleSetup(props.data.role.role)
  }, [roleSetup]);
  

  

  function loadRoleSetup(role){
    console.log(role)
    props.requestcomponent(roleSetup[role], { requestcomponent: props.requestcomponent})
  }

 
  return (
    <>
    </>
  );
};

