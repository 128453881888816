import {Form, FormControl, InputGroup, DropdownButton, Button, Dropdown, Table, ListGroup} from 'react-bootstrap'
import React, {useState, useEffect} from 'react'
import { withRouter } from "react-router";
import {gql, useQuery, useMutation} from '@apollo/client'
import Container from 'react-bootstrap/Container';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap/';
import { useHistory } from "react-router-dom";
import ShoppingCartManager from './ShoppingCartManager'
import FeaturedMPCard from '../featuredmp'
import Locations from '../Locations'

import {
  useLocation
} from "react-router-dom";

const featuredprod = [1]

const cartProductsQ = gql`
query getCartProducts($ids: [String!]!){
  getCartProducts(ids: $ids){
    status
    code
    ... on CartProductsResponse{
      cartproducts{
        products{
          id
          name
          images
          availableQuantity
          vendor
          price
        }
    loggedIn
        addresses{
        addresses{
          streetNo
          streetName
          district
          municipality
        }
        primaryAddress
        }
      }
    }
    }
  }

`

const  createOrderM = gql `
    mutation createOrder($orders: OrderInput!){
      createOrder(orders: $orders){
        status
        code
        ... on AcknowledgedResponse{
          data
        }
      }
    }
  `


let locations = new Locations()

const server = process.env.REACT_APP_IMAGE_URL

function CartPage(props) {
    var cartData = []
    var [state, setState] = useState(false)
    var [productState, setProductState] = useState(false)
    if(localStorage.getItem('shoppingCart') !== ""){
        console.log("shouldnt")
        cartData =  JSON.parse(localStorage.getItem('shoppingCart')) || [];
    }
 
  const productIds = cartData.map(function (product) {return product.id})
  const {loading, error, data, refetch} = useQuery(cartProductsQ,  { fetchPolicy: "no-cache", variables: {ids: productIds}})
console.log(cartData)
    
    const FeaturedProd =  featuredprod.map((location, idx) =>

    <React.Fragment key={idx}>
    <div className="col mb-4">
    <FeaturedMPCard data={idx} control={1}/>
    </div>

    </React.Fragment>
  )
  
   // setTimeout(function(){
    //document.getElementById('div1').style.visibility = "visible";
   // },1000);
  return (
    <>
    {
      loading && 
      <div id="overlay">

      <div className="loader"></div>
      </div>

    }
 
          <div className="container-fluid topP AppBodyController">
            
          <div className="row w80">

    { data?.getCartProducts.status ?
        <>
        <h1 className="text-center">Shopping Cart </h1>
        <CartContainerPage cartProducts={data?.getCartProducts.cartproducts} cartData={cartData} update={(x)=>setState(x)} updateProduct={(x) => setProductState(x)} productState={productState} />
        </>
        :
        <>

        </>
        
    }
          
          {
              
             state ?
              <>
              
              <h4  style={{color: "green"}} className="text-center">Checkout Complete</h4>
              <h1 style={{color: "green", fontSize:"4em"}} className="text-center">  <i class="bi bi-check-circle"></i></h1>
              <p className="text-center"> Your order has ben placed.</p>
              <p className="text-center">You can track the status of your order via the orders page in your account</p>
              </>
              
              :
              
              <>
          
              

              </>
          }
          
          {
              cartData.length == 0 && !state ?
              
              <>
              <h1 className="text-center">Shopping Cart </h1>
              <h5 className="text-center">Add some Products to your Shopping Cart</h5>
              
              <div className="container-fluid  topP">
              <h5 className="text-center fontSmallCaps darkSlateGray ">Featured Products</h5>
              <hr/>
             

              {FeaturedProd}

              </div>
              </> : <></>
              
          }
          
          
          
</div>
          </div>

    </>
  );
}

const ProductPageWithRouter = withRouter(CartPage);
export default CartPage;






const CartContainerPage= props => {
 console.log(props)
  let history = useHistory()

  const [state, setState] = useState(() => {
    let products = []
    let productsData = []
    for(const product of  props.cartProducts.products){
        console.log(product)
      for(const prod of props.cartData){
          console.log(prod)
        if(prod.id == product.id){
          let productHolder = {
            id: product.id,
            quantity: prod.quantity,
            price: product.price,
            storeCode: prod.storeCode,
            storeName: prod.storeName
          }
          let productStateHolder = {
            id: product.id,
            quantity: prod.quantity,
            price: product.price,
            image: product.images[0],
            name: product.name,
            availableQuantity: product.availableQuantity,
            vendor: product.vendor
          }
          productsData = productsData.concat(productStateHolder)
          products = products.concat(productHolder)
        }
      }
    }
    //ShoppingCartManager.replaceAllShoppingCart(products)

    return {cart: productsData, cartData: products, delivery: "pickup", loaded: false}
  })
  console.log(state) 

    
    const [createOrder, { loading, error, data }] = useMutation(createOrderM, {
      onCompleted: (data) =>{
        if(data !== undefined ){
          console.log(data)
          if(data.createOrder === null){
          }else{
            if(!data.createOrder.status){
              return (<p> Order not added </p>)
            }
            ShoppingCartManager.clearShoppingCart()
              props.update(true)

          }

        }

      },
      onError: (error) => console.error("Error", error)
    })
    
    
    
  function load(e){
    e.preventDefault()
    console.log(e)
    history.push("/store/" + e.target.dataset.id);
  }

    function checkout(){
        let vendorOrder = []
        var o = {}
        var result = state.cart.reduce(function(r, el) {
          var e = el.vendor
          if (!o[e]) {
            o[e] = {
              vendorId: el.vendor,
              productsOrdered: []
            }
            r.push(o[e]);
          }
            o[e].productsOrdered.push({
                productId: el.id,
                quantity: el.quantity,
                price: el.price
            });
          return r;
        }, [])
        
      let pickup = true
        let orders = {
        vendorOrder: result,
        pickup: pickup
    }
        var deliveryAddress = {}
        console.log(state.delivery)
        if(state.delivery !== "pickup" && state.delivery !== ""){
            pickup = false
            var index  = state.delivery.substring(1);
            let deliveryAddressHolder = props.cartProducts.addresses.addresses[parseInt(index)]
             deliveryAddress = {
                streetNo: deliveryAddressHolder.streetNo,
                streetName: deliveryAddressHolder.streetName,
                district: deliveryAddressHolder.district,
                municipality: deliveryAddressHolder.municipality
            }
            console.log(deliveryAddress)
            orders = {
            vendorOrder: result,
            pickup: pickup,
            address: deliveryAddress
        }
            
        }
        console.log(orders)

       createOrder(
                    {
                    variables:{
                    orders: orders
                    }
                    }
        )
        
        
        
    }



  function handleQuantityChange(e){
    var regex=/^[0-9]+$/;
    const value = e.currentTarget.value
    if (value.match(regex)){
      setState(state => {

        return {
          ...state,
          quantity: parseInt(value)
        }
      })
    }
  }
    
    function loadVendor(e){
        e.preventDefault()
        console.log(e)
      history.push("/store/" + e.target.dataset.id);
    }
    
    function deleteItem(e){
        e.preventDefault()
        console.log(e.target.dataset.id)
        var cartData = []
        var holder = []
        if(localStorage.getItem('shoppingCart') !== ""){
            
            cartData =  JSON.parse(localStorage.getItem('shoppingCart')) || [];
            for(var product of cartData){
                console.log(e)
                console.log(product.id)
                if(e.target.dataset.id !== product.id){
                    holder.push(product)
                }
               
            }
            ShoppingCartManager.replaceAllShoppingCart(holder)
            var event = new Event('itemInserted');
           document.dispatchEvent(event);
            props.updateProduct(!props.productState)
        }
        
    }
    
    function getDeliveryFee(){
        var stores = []
        for(var product of state.cartData){
            stores.push(product.storeCode)
        }
        let holder = [... new Set(stores)]
        return parseFloat(holder.length).toFixed(2)
    }
    
    function loadProduct(e){
        e.preventDefault()
        history.push("/product/" + e.target.dataset.id);
        
    }

  const products = state.cart.map((product, idx) =>
    <React.Fragment  key={idx}>
 
 
 

 <ListGroup.Item className="d-flex  align-items-center">
 

                                  <a href="#" onClick={(e)=> loadProduct(e)} data-id={product.id}> <img className=" rightSpacer" data-id={product.id} src={server + product.image} width="125px" /></a>
    &nbsp; &nbsp;
    <div className="w-100">
    <span className="mb-0 ">
    <h5>{product.name}</h5>
                                  <h6>Sold By:  <a href="#"  data-id={state.cartData[idx].storeCode} onClick={(e) => loadVendor(e)} >{state.cartData[idx].storeName}</a></h6>
    {product.availableQuantity == 0 ?  <small className="redText">Out of Stock </small> :  <small className="forestGreen">In Stock </small>}
   
                                  <h6><small>{state.cartData[idx].quantity} X ${parseFloat(state.cartData[idx].price).toFixed(2)} </small> <a href="#" onClick={(e)=>deleteItem(e)} data-id={product.id}><small data-id={product.id}>Remove <i className="bi bi-trash" data-id={product.id}></i></small> </a>  </h6>
                                  
    </span>
    </div>
    <div className="w-100">
    <h6 className="mb-0 text-right f400">${(product.price.toFixed(2) * state.cartData[idx].quantity).toFixed(2)}</h6>
    </div>

    </ListGroup.Item>


    </React.Fragment>

  )
  
  const addresses= props.cartProducts.addresses.addresses.map((address, idx) =>
    <React.Fragment  key={idx}>
 
 
 



     <ListGroup.Item className="d-flex  align-items-center">
 
 <Form.Check
          type="radio"
          label={address.streetNo +" " +  address.streetName + ", " + getObjectKey(locations.getMunicipalityMatch() ,address.municipality) + ", " +  getObjectKey(locations.getDistrictMatchControl(), address.district)}
          name="formHorizontalRadios"
          id="formHorizontalRadios1"
        value={"D" + idx}
        onChange={onDeliveryChange}
        />

    </ListGroup.Item>



    </React.Fragment>

  )

    function onDeliveryChange(event){
        console.log(event.target.value)
        
        setState(state =>{
            return {
                ...state,
                delivery: event.target.value
            }
        })
    }

  function getObjectKey(obj, value) {
    return Object.keys(obj).find((key) => obj[key] === value);
  }

    function getSubTotal(){
        var subtotal = 0;
        for(var product of state.cart){
            if(product.availableQuantity >= product.quantity){
                subtotal = subtotal + (product.quantity * product.price)
            }
            
        }
        return (subtotal)
    }
    
    function displaySubtotal(){
 
       return parseFloat(getSubTotal()).toFixed(2)
    
    }
    
    function getTotal(){
        var del = 0
        if(state.delivery !== "pickup"){
            del = 5.00 * getDeliveryFee()
        }
        var total = getSubTotal() + del
         return parseFloat(total).toFixed(2)
    }
    
    function getDeliveryDisplay(){
        if(state.delivery === "pickup"){
            
        }else{
            return(
                   <React.Fragment>
                   <ListGroup.Item className="d-flex  align-items-center">
                   <div className="w-100">
                   <span className="mb-0 ">
                   <h6>Delivery</h6><p>$5 fee on each vendor</p>
                   </span>
                   </div>
                   <div className="w-100">
                   <h6 className="mb-0 text-right f400">${parseFloat(5.00 * getDeliveryFee()).toFixed(2)}</h6>
                   </div>
                   </ListGroup.Item>
                   </React.Fragment>
                   )
        }
        
    }


  return (
    <>


 
    <div className="col-md-8">

    <ListGroup variant="flush" className="d-flex    ">
    <ListGroup.Item><h6>Products</h6></ListGroup.Item>
    {products}



 
          
          
          
          <ListGroup.Item className="d-flex  align-items-center">
         
          
          <div className="w-100">
          <span className="mb-0 ">
          <h6>Subtotal</h6>
          </span>
          </div>
          <div className="w-100">
          <h6 className="mb-0 text-right f400">${displaySubtotal()}</h6>
          </div>
          </ListGroup.Item>
        
          
          {getDeliveryDisplay()}
      
           
          
          <ListGroup.Item className="d-flex  align-items-center">
          <div className="w-100">
          <span className="mb-0 ">
          <h6>Total</h6>
          </span>
          </div>
          <div className="w-100">
          <h6 className="mb-0 text-right f400">${getTotal()}</h6>
          </div>
          </ListGroup.Item>
          
          <h6 className="mb-0 text-right f400"> </h6>



    </ListGroup>
  <div className="container topP">
    <div className="row-fluid text-right">
          {
              
              props.cartProducts.loggedIn ?
              <Button variant="outline-success" size="md"      onClick={checkout}>
         Checkout
       </Button>
              :
              <h6>Login for Checkout</h6>
          }
          
    </div>
</div>

    </div>
    <div className="col-md-4">

<ListGroup variant="flush" className="d-flex    ">
   
          <ListGroup.Item className="d-flex  align-items-center">
      
      <Form.Check
               type="radio"
               label="Pick Up"
                value="pickup"
          onChange={onDeliveryChange}
               name="formHorizontalRadios"
               id="formHorizontalRadios1"
          checked={state.delivery === "pickup"}
             />

         </ListGroup.Item>
          <ListGroup.Item><h6>Delivery</h6></ListGroup.Item>
    {addresses}
          
    <ListGroup.Item className="d-flex  align-items-center">
          
    <div className="w-100">
    </div>
    </ListGroup.Item>




    </ListGroup>


 

    </div>

    



    </>

  )
}



/*
 *
  <ListGroup.Item className="d-flex  align-items-center">


              <img class=" rightSpacer" src={server + product.image} width="125px" /> 
<div class="w-100">
                <span class="mb-0 ">{product.name}
                <p>asd</p>

                </span>
              </div>
             <div class="w-100">
                <h6 class="mb-0 text-right f400">${product.price}</h6>
              </div>

      </ListGroup.Item>
      */


/*
 *

     <ListGroup.Item className="d-flex justify-content-between">
    <span>
  <img className="rightSpacer"  width="125px" src={server + product.image} alt=""/>
    {product.name}
</span>
<span className="">${product.price.toFixed(2)}</span>
      </ListGroup.Item>

*/
