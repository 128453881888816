import React, {useState, useEffect} from "react"
import {Card} from 'react-bootstrap'
import {gql, useQuery} from '@apollo/client'

const orders = gql`
{
    getCustomerOrders{
        status
        code
        ... on CustomerOrdersResponse{
            customerorders{
                vendor
                vendorStore
                order{
                    id
                    timeStamp
                    lookupCode
                    status
                    productOrder{
                        productId
                        quantity
                        price
                    }
                address{
                    streetNo
                    streetName
                    district
                    municipality
                }
                }
                vendorAddress{
                    streetNo
                    streetName
                    district
                    municipality
                }
}
}

}
}

`

export const OrdersAccountPage = props => {
    
    const {loading, error, data} = useQuery(orders, {fetchPolicy: "no-cache"})
    if(loading){console.log(loading)}
    console.log(data)
    
    
    function goBack(event){
      props.requestcomponent(props.data.sender, { requestcomponent: props.requestcomponent, sender: props.data.previous})
      
    }
  
    
    const customerOrders = data?.getCustomerOrders?.customerorders?.map((order, idx) =>
        <React.Fragment key={idx}>
           
            <OrdersVendorCard order={order.order} vendor={order.vendor}
            vendorStore={order.vendorStore} vendorAddress={order.vendorAddress} data={props.data} requestcomponent={props.requestcomponent}/>
           
        </React.Fragment>
                                                                       
                                                                       
        )
    
  return (
    <>
          <br/>
          <Card.Header className="">
               <div className="row ">
                 <div className="col-md-4">
                 
                  </div>
                    <div className="col-md-4">
                <h5 className=" text-center darkSlateGray  ">Orders</h5>
                </div>
                  <div className="col-md-4">
                  </div>
                </div>
                </Card.Header>
              
               
               <hr/>
          
          {
              data?.getCustomerOrders.status ?
              
                <>
                
              <div className="container   ">

              <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 ">
              {customerOrders}
              </div>
              </div>
              </>
              :
              <p></p>
              
          }
    </>
  );


};




export const OrdersVendorCard = props => {

    

    
    
    function getTotalItems(){
        let quantity = 0;
        for(var item of props.order.productOrder){
            quantity = quantity + item.quantity
        }
        return quantity
        
    }
    function getTotalPrice(){
        let price = 0;
        for(var item of props.order.productOrder){
            price = (item.price * item.quantity) + price
        }
        return price
        
    }
    function getDate(){
        console.log(parseInt(props.order.timeStamp))
        const event = new Date(parseInt(props.order.timeStamp));
        
        return event.toDateString()
    }
    
    function viewOrder(e){
        let productOrder = getHolder()
        let order = {
            order: productOrder,
            vendor: props.vendor,
            vendorStore: props.vendorStore,
            vendorAddress: props.vendorAddress
        }
        
        props.requestcomponent("View Customer Order", {sender: "Orders", previous: props.data.sender,order: order,     requestcomponent: props.requestcomponent})
        
    }
    
    function getHolder(){
        
        let idHolder  = []
        for(var product of props.order.productOrder){
            console.log(product)
            idHolder.push(product.productId)
        }
        console.log(idHolder)
        var order = props.order
        order["productIds"] = idHolder
        return order
    }
    
    
  return (
    <>
          <div className="col mb-4 bottomP">
          <div className="card">
          
          <a href="#" onClick={e => viewOrder(e)}   className="">
          
           <Card.Header className=""> <h6 className=" text-center darkSlateGray  ">Order: {props.order.lookupCode}</h6></Card.Header>
        
          <Card.Body>
          
          <span>Total Items: {getTotalItems()}</span>
          <p>Total Price: ${getTotalPrice()}</p>
          <span>Sold By: {props.vendor} </span>
          <p>{props.order.address != null ? "Delivery" : "PickUp"} </p>
          <p>Ordered on {getDate()}</p>
          
          </Card.Body>
         
          <Card.Footer className="text-center">
          <h6>Status: {props.order.status}</h6>
          </Card.Footer>
          </a>
         </div>
          
     </div>
          
          
    </>
  );


};



