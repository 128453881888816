import React, {useState, useEffect} from "react"
import {Card, NavDropdown} from 'react-bootstrap'
import {gql, useQuery} from '@apollo/client'
import { useHistory } from "react-router-dom";
const vendors = gql`
{
    getAllVendors{
        status
        code
        ... on VendorStoresResponse{
            vendors{
            id
            name
            accessCode
}
}

}
}
`

export const AllVendorsMenu = props => {
    console.log(props)
    let history = useHistory()
    const {loading, error, data} = useQuery(vendors, {fetchPolicy: "no-cache"})
    if(loading){console.log(loading)}
    console.log(data)
    
    const vendorsList = data?.getAllVendors?.vendors?.map((vendor, idx) =>
    <React.Fragment key={idx}>
    <NavDropdown.Item href="#" data-id={vendor.accessCode}onClick={(e)=> loadStore(e)}>{vendor.name}</NavDropdown.Item>
    </React.Fragment>
    )
    function loadStore(e){
        e.preventDefault()
        console.log(e.target.dataset.id)
      history.push("/store/" + e.target.dataset.id);
    }
  return (
    <>
          <NavDropdown title="All Vendors" id="basic-nav-dropdown">
                      
          {vendorsList}
                       
                     </NavDropdown>
    </>
  );


};


