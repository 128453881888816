import React, {useState, useEffect} from 'react'
import {gql, useQuery, useMutation, useLazyQuery} from '@apollo/client'
const everifyM = gql`
  mutation validateEmail($token: String!) {
    validateEmail(token: $token)
  }


`

function EmailVerificationPage(props) {
  let verificationCode = props.location.pathname.slice(9)
  const [state, setState] = useState(false)

  console.log(verificationCode)

  const [validateEmail, { loading, error, data }] = useMutation(everifyM, {
    onCompleted: (data) =>{
      if(data !== undefined ){
        console.log(data)
        if(data.validateEmail === null || data.validateEmail == false){
        }else{
            setState(true)
        
        }
      }
    },
    onError: (error) => console.error("Error", error)
  })

  useEffect(() => {

    validateEmail({ variables: { token: verificationCode} }) 

  }, [validateEmail]);


  return (
    <>

    <div className="jumbotron parent whiteBG sVP">
    {
      loading && 
      <div id="overlay">

      <div className="loader"></div>
      </div>

    }
      {
        state && 
        <h3 className="text-center">Email Succesfully Verified
        </h3>

      }



    </div>

    </>
  );



}

export default EmailVerificationPage;

