import {WelcomeAccountPage} from './welcomeaccount'
import {VerifyEmailPage} from './verifyEmail'
import {CustomerAddressesPage} from './customerAddresses'
import {OrdersAccountPage} from './orders'
import {CustomerOrderPage} from './order'
const apps = [
    {
      name: 'Welcome',
      app: WelcomeAccountPage
  },
  {
    name: "Verify Email",
    app: VerifyEmailPage
  },
  {
    name: "Addresses",
    app: CustomerAddressesPage
  },
    {
        name: "Orders",
        app: OrdersAccountPage
    },
    {
        name:"View Customer Order",
        app: CustomerOrderPage
    }

    
]

class ComponentManager {
  constructor(){

  }

  getComponent(name){
      for(var app of apps){
            if(app.name.valueOf() === name.valueOf()){
                return  app
            }
        }
    return false

  }
}

export default ComponentManager
 

         
