import {WelcomeVendorPage} from './welcomevendor'
import {AddProductPage} from './addproduct'
import {VendorProductsPage} from './getVendorProducts'
import {ViewProductPage} from './viewProduct'
import {ActiveVendorProductsPage} from './getActiveVendorProducts'
import {VendorProfilePage} from './vendorProfile'
import {StoreMenuPage} from './storeMenu'
import {OrdersVendorPage} from './orders'
import {VendorOrderPage}  from './VendorOrderPage'
const apps = [
    {
      name: 'Welcome',
      app: WelcomeVendorPage
  },
  {
      name: 'Add Product',
      app: AddProductPage
  },
  {	
  	   name: 'All Products',
  	   app: VendorProductsPage
  },
  
 {	
  	   name: 'View Product',
  	   app: ViewProductPage
  },
   {	
  	   name: 'Active Products',
  	   app: ActiveVendorProductsPage
  },
  {
  		name: 'Vendor Profile',
  		app: VendorProfilePage
  },
  {
  		name: 'Store Menu',
  		app: StoreMenuPage
  },
    {
        name:"Orders",
        app: OrdersVendorPage
    },
    {
        name: "Vendor Order",
        app: VendorOrderPage
    }
  
    
]

class ComponentManager {
  constructor(){

  }

  getComponent(name){
      for(var app of apps){
            if(app.name.valueOf() === name.valueOf()){
                return  app
            }
        }
    return false

  }
}

export default ComponentManager
 

         
