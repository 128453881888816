import { Route, Redirect } from "react-router-dom";

export const LogoutContainer = ({
  component: Component,
  ...rest
}) => {

   
    
    
    return(
      <Route
        {...rest}
        render={props => {
            return (
              <div>
                <Component {...props} />
              </div>
            );
        }}
      />
    )
  

 
};


