import {Card, Badge, Col, Button, Form, Toast} from 'react-bootstrap'
import React, {useRef, useState} from "react";
import {gql, useMutation} from '@apollo/client'

const  updateProductM = gql `
    mutation updateProduct($updatedProduct: UpdatedProductInput!){
      updateProduct(updatedProduct: $updatedProduct){
        status
        code
        ... on AcknowledgedResponse{
        	data
      	}
      }
    }
  `

const  deleteProductM = gql `
    mutation deleteProduct($id: String!, $vendor: String!){
      deleteProduct(id: $id, vendor: $vendor){
        status
        code
        ... on AcknowledgedResponse{
        	data
      	}
      }
    }
  `


const server = process.env.REACT_APP_IMAGE_URL

 var initialState = {
    name: '',
    description: '',
    images: [],
    availableQuantity: 0,
    price: 0.0,
    descriptors: [],
    status: "true",
    sale: "",
    type: "",
    keywords: [],
    keywordholder: "",
    edit: false,
 }

export const ViewProductPage = props => {

const [show, setShow] = useState(false);
const [state, setState] = useState({keywordholder: "", edit: false, descriptorName: "", descriptorDescription: "", newImages: [], ... props.data.product,
 status: (props.data.product.status ? "true" : "false"), length: props.data.product.package.length, width: props.data.product.package.width, depth: props.data.product.package.depth, 
 weight: props.data.product.package.weight,  deliveryAvailable: (props.data.product.deliveryAvailable ? "true" : "false"),})
console.log(state)


  const [updateProduct, { loading, error, data }] = useMutation(updateProductM, {
    onCompleted: (data) =>{
      if(data !== undefined ){
       console.log(data)
        if(data.updateProduct === null){
        }else{
            if(!data.updateProduct.status){
            return (<p> Product not Updated </p>)
          }
          setEdit()
          setShow(true)
        
        }

      }

    },
    onError: (error) => console.error("Error", error)
  })
  
  
    const [deleteProduct, { loading: loadingD, error: errorD, data: dataD }] = useMutation(deleteProductM, {
    onCompleted: (dataD) =>{
      if(dataD !== undefined ){
       console.log(dataD)
        if(dataD.deleteProduct === null){
        }else{
            if(!dataD.deleteProduct.status){
            return (<p> Product not Deleted </p>)
          }
     		goBack()
        
        }

      }

    },
    onError: (error) => console.error("Error", error)
  })
  
  
    async function handleDeleteProduct() {
 
  
      deleteProduct({ variables: {
      	id: props.data.product.id,
      	vendor: props.data.product.vendor
      } });
  }


  async function handleUpdateProduct() {
 
  
      updateProduct({ variables: {updatedProduct: {
          name: state.name,
          description: state.description,
          images: state.images,
          availableQuantity: parseInt(state.availableQuantity),
          price: parseFloat(parseFloat(state.price).toFixed(2)),
          descriptors: state.descriptors.map((data) => {
  					return {
    					'name':data.name,
    					'description':data.description
  					}
					}),
          keywords: state.keywords,
          sale: state.sale,
          type: state.type,
          status: (state.status === "true") || (state.status == true),
          deliveryAvailable: (state.deliveryAvailable === "true") || (state.deliveryAvailable == true),
          newImages: state.newImages,
          id: props.data.product.id,
          package:{
            length: parseFloat(parseFloat(state.length).toFixed(2)),
            width: parseFloat(parseFloat(state.width).toFixed(2)),
            depth: parseFloat(parseFloat(state.depth).toFixed(2)),
            weight: parseFloat(parseFloat(state.weight).toFixed(2))
          }

      }} });
  }


function goBack(event){
  props.requestcomponent(props.data.sender, { requestcomponent: props.requestcomponent, sender: props.data.previous})
  
}

const keywordsList = state.keywords.map((value, idx) =>
    <React.Fragment  key={idx}>
       <div className="col mb-4 bottomP">
           <Badge  variant="success"><h6 className="text-white">{value}</h6></Badge>
           {
           	state.edit &&
           	<i className="btn-space fa fa-times-circle redText" onClick={e => removeKeyword(e)} aria-hidden="true" data-id={idx} ></i> 
           }
          
        </div>
    </React.Fragment>

)

const descriptorsList = state.descriptors.map((value, idx) =>
    <React.Fragment  key={idx}>
       <div className="col mb-4 bottomP">
       
           <span>{value.name } : {value.description } </span>
            {
     	state.edit && 
			<i className="btn-space fa fa-times-circle redText" onClick={e => removeDescriptor(e)} aria-hidden="true" data-id={idx} ></i> 
   		 }
        </div>
    </React.Fragment>

)

const productImageList = state.images.map((value, idx) =>
    <React.Fragment  key={idx}>
       <div className="col mb-4 bottomP">
           <img width="150px" src={server + value} className="topP" />
           
        {
     	state.edit && 
			<i className="btn-space fa fa-times-circle redText" onClick={e => removeImage(e)} aria-hidden="true" data-id={idx} ></i> 
   		 }
        
          
          
        </div>
    </React.Fragment>
)


function setEdit(e){
  setState(state => {
    return{
      ...state,
      edit: !state.edit
    }
  })
}

  const handleInput = e => {
    const inputName = e.currentTarget.name
    const value = e.currentTarget.value
          setState(prev => ({...prev, [inputName]: value}))
  }
  
    function addKeyword(e){
    e.preventDefault()
    setState(state => {
      const keywordsList = state.keywords.concat(state.keywordholder.toLowerCase().trim())
      return {
        ...state,
        keywords: keywordsList,
        keywordholder: ""
      }
    })
}

    function addDescriptor(e){
    e.preventDefault()
    setState(state => {
      const descriptorsList = state.descriptors.concat(
      {
		name: state.descriptorName, description: state.descriptorDescription      
      }
      )
      return {
        ...state,
        descriptors: descriptorsList,
        descriptorName: "",
        descriptorDescription: ""
      }
    })
}

function onValueChangeType(event) {

 setState(state => {
      return {
        ...state,
        type: event.target.value
      }
    })

}


function onValueChangeSale(event) {

 setState(state => {
      return {
        ...state,
        sale: event.target.value
      }
    })

}


  function onValueChangeDelivery(event) {

    setState(state => {
      return {
        ...state,
        deliveryAvailable: event.target.value
      }
    })

  }



function onValueChange(event) {

 setState(state => {
      return {
        ...state,
        status: event.target.value
      }
    })

}

function removeImage(e){
    console.log(e.target.dataset.id)
   // document.getElementById("productImages").value = null 
    setState(state => {
        var productImagesList = state.images
        productImagesList.splice(e.target.dataset.id, 1)
        return {
          ...state,
          images: productImagesList
        }
      })
}


function removeNewImage(e){
    console.log(e.target.dataset.id)
    document.getElementById("productImages").value = null 
    setState(state => {
        var productImagesList = state.newImages
        productImagesList.splice(e.target.dataset.id, 1)
        return {
          ...state,
          newImages: productImagesList
        }
      })
}

function removeDescriptor(e){
    setState(state => {
        var descriptorsList = state.descriptors
        descriptorsList.splice(e.target.dataset.id, 1)
        return {
          ...state,
          descriptors: descriptorsList
        }
      })
}

function removeKeyword(e){
    setState(state => {
        var keywordsList = state.keywords
        keywordsList.splice(e.target.dataset.id, 1)
        return {
          ...state,
          keywords: keywordsList
        }
      })
}


function loadImage(e){
    var reader = new FileReader();
     reader.onload = function(){
        addProductImage(e, reader.result)
    };
     reader.readAsDataURL(e.target.files[0]); 
  }

  function addProductImage(e, image){
    e.preventDefault()
    setState(state => {
      const productImagesList = state.newImages.concat(image)
      return {
        ...state,
        newImages: productImagesList
      }
    })
}

const newProductImageList = state.newImages.map((value, idx) =>
    <React.Fragment  key={idx}>
       <div className="col mb-4 bottomP">
           <img width="150px" src={value} className="topP" />
           <i className="btn-space fa fa-times-circle redText" aria-hidden="true" data-id={idx} onClick={e => removeNewImage(e)}></i> 
          
        </div>
    </React.Fragment>
)
/*

*/
  return (
    <>
          <div className="row-fluid bottomP">
              <Card.Header className="">
           <div className="row ">
             <div className="col-md-4">
             <a href="#" className="forestGreen text-start "> <span onClick={e => goBack(e)} ><i className="fas fa-arrow-left"></i> Back </span> </a>
          	</div>
          	  <div className="col-md-4">
            <h5 className=" text-center darkSlateGray  ">Product</h5>
            </div>
              <div className="col-md-4">
          <Toast onClose={() => setShow(false)} show={show} delay={3500} autohide>
               <Toast.Header>
                   <i className="fas fa-check-circle "></i>
                 <strong className="mr-auto"> Vendor Tools</strong>
                 <small>Just now</small>
               </Toast.Header>
               <Toast.Body>Product Successfully Updated</Toast.Body>
             </Toast>
              </div>
            </div>
            </Card.Header>
 
          </div>
    <hr/>
 
  
          
          <div className="container ">
   
    <div className="row">
      <div className="col-md-8"></div>
        <div className="col-md-4">
         
    </div>
    </div>
    
          <div className="row">
          <div className="col-md-3">
          <h6 className="">{state.name}</h6>
          </div>
          { state.edit &&
          <div className="col-md-4">
              
          <Form.Control  size="sm" required type="text" placeholder="Product Name" name="name" value={state.name} onChange={handleInput} />
         </div>
         }
         </div>
     <p>{state.description}</p>
        {
     	state.edit && 
         <Form.Control className="bottomP" as="textarea" rows={3} placeholder="Description" name="description" value={state.description} onChange={handleInput} />
         }
          <div className="row">
          <div className="col-md-3">
          <p>Available Quantity: {state.availableQuantity}</p>
          </div>
          {state.edit &&
          <div className="col-md-3">
           <Form.Control  size="sm" required type="number" placeholder="Quantity" name="availableQuantity" value={state.availableQuantity} onChange={handleInput} />
         
          </div>
          }
          </div>
          <div className="row">
          <div className="col-md-3">
          <p>Price: ${state.price}</p>
          </div>
          { state.edit && 
             <div className="col-md-3">
             <Form.Control className="bottomP" size="sm" required type="number" placeholder="Price" name="price" value={state.price} onChange={handleInput} />
         
             </div>
             }
             </div>
             
             
             <div className="row">
             <div className="col-md-4"> 
          <p>Sale Type: {state.sale}</p>
          
          </div>
          {state.edit && 
          <>
  <div className="col-md-4">
  <input
  type="radio"
  value="RETAIL"
  checked={state.sale === "RETAIL"}
  onChange={onValueChangeSale}
  />
  <label className="sRP">
  Retail
  </label>

  </div>


  <div className="col-md-4">
  <input
  type="radio"
  value="WHOLESALE"
  checked={state.sale === "WHOLESALE"}
  onChange={onValueChangeSale}
  />
  <label className="sRP">
  Wholesale
  </label>

  </div>
      </>    
       }   
          </div>
          
          <div className="row">
           <div className="col-md-4">
          <p>Product Type: {state.type}</p>
          </div>
         {state.edit &&
         <>
  <div className="col-md-4">
  <input
  name="type"
  type="radio"
  value="FOOD"
  checked={state.type === "FOOD"}
  onChange={onValueChangeType}
  />
  <label className="sRP">
  Food
  </label>

  </div>



  <div className="col-md-4">
  <input
  name="type"
  type="radio"
  value="SUPPLIES"
  checked={state.type === "SUPPLIES"}
  onChange={onValueChangeType}
  />
  <label className="sRP">
  Supplies
  </label>

  </div>
</>
}
 </div>
          
          <div className="row">
             <div className="col-md-4">
          <p>Active in Store: {(state.status == "true" || (state.status == true))  ? "Yes " : "No "}</p>
          </div>
           {
     	state.edit && 
     	<>
          <div className="col-md-4">
  <input
  type="radio"
  value="true"
  checked={state.status === "true"}
  onChange={onValueChange}
  />
  <label className="sRP">
  Active
  </label>

  </div>


  <div className="col-md-4">
  <input
  type="radio"
  value="false"
  checked={state.status === "false"}
  onChange={onValueChange}
  />
  <label className="sRP">
  Inactive
  </label>

  </div>
       </>
         }     
</div>







  <div className="row">


    <div className="col-md-4">
<p>Delivery: {(state.deliveryAvailable == "true" || (state.deliveryAvailable == true))  ? "Available" : "Unavailable"}</p>
  </div>
   {state.edit && 
          <>  

  <div className="col-md-4">
  
    <input
    type="radio"
    value="true"
    checked={state.deliveryAvailable === "true"}
    onChange={onValueChangeDelivery}
    />
    <label className="sRP">
    Available
    </label>

    </div>


    <div className="col-md-4">
    <input
    type="radio"
    value="false"
    checked={state.deliveryAvailable === "false"}
    onChange={onValueChangeDelivery}
    />
    <label className="sRP">
    Unavailable
    </label>

    </div>
</>
   }

</div>
          
 <div className="row bottomP">
    <div className="col-md-4">
    <label className="">
    Dimensions In Inches and Pounds (Needed for Delivery)
<div className='row-fluid'>
    Length: {state.length}in,  Width: {state.width}in,  Depth: {state.depth}in, Weigth: {state.weight}lbs
  </div> 
  </label>

    </div>
 {state.edit && 
          <>  
    <div className="col-md-2">Length
    <Form.Control  size="sm" required type="number" placeholder="Lenght" name="length" value={state.length} onChange={handleInput}  onKeyDown={() => {return false}}   />

    </div>
    <div className="col-md-2">Width
    <Form.Control  size="sm" required type="number" placeholder="Width" name="width" value={state.width} onChange={handleInput} />


    </div>
    <div className="col-md-2">Depth
    <Form.Control  size="sm" required type="number" placeholder="Depth" name="depth" value={state.depth} onChange={handleInput} />


    </div>
    <div className="col-md-2">Weight
    <Form.Control  size="sm" required type="number" placeholder="Weight" name="weight" value={state.weight} onChange={handleInput} />


    </div>
   </>
 }
    </div>
 
     
       
     <div className="row">
      <div className="col-md-2">
          <p>Descriptors</p>
     </div>
     {state.edit && 
     <>
      <div className="col-md-4">
       <Form.Control className="bottomP" size="sm" required type="text" placeholder="Name" name="descriptorName" value={state.descriptorName} onChange={handleInput} />
         
     </div>
      <div className="col-md-4">
        <Form.Control className="bottomP" size="sm" required type="text" placeholder="Description" name="descriptorDescription" value={state.descriptorDescription} onChange={handleInput} />
         
     </div>
      <div className="col-md-2">
          <Button className="bottomP" variant="outline-dark" onClick={e => addDescriptor(e)}><i className=" fa fa-plus-circle" aria-hidden="true"></i> <span className="">Add </span></Button>
  
     </div>
     </>
     }
     </div>
     
           	 <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-6">
   				{descriptorsList}

 				</div>

<div className="row">
<div className="col-md-2">
          
       	<span>Keywords:     
</span>
</div>
{
     	state.edit && 
     	<>
<div className="col-md-4">
 <Form.Control  size="sm" required type="text" placeholder="Keyword" name="keywordholder" value={state.keywordholder} onChange={handleInput} />
    
</div>
<div className="col-md-2">
  <Button className="bottomP" variant="outline-dark" onClick={e => addKeyword(e)}><i className=" fa fa-plus-circle" aria-hidden="true"></i> <span className="">Add </span></Button>
           
</div>
</>
}
</div>
       	 <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-6">
   				{keywordsList}

 				</div>
 				<div className="row">
 				 <div className="col-md-8">
 				{state.edit &&
          <>
          <span>Product Images (Use Square Images for better viewing as images will be squared)</span>
 				 <Form.Control className="bottomP" id="productImages" type="file" size="sm" onChange={e => loadImage(e)}/>
 			  </>
        }
 				</div>
 				</div>
            <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
   				{productImageList}
   				{newProductImageList}

 				</div>
 				
 			  	
          </div>
          
          <div className="container bottomP">
                <div className="row container  ">
   		  <div className="col-md-4">
          <Button className="btn-space" variant="outline-danger" onClick={e => handleDeleteProduct()} ><i className=" fa fa-trash" aria-hidden="true"></i><span className=""> Delete Product</span></Button>
          </div>
          <div className="col-md-4">
          <Button className="btn-space text-center" variant="outline-dark" onClick={e => setEdit()}><i className="fas fa-pencil-alt"></i><span className=""> Edit Product</span></Button>
      		</div>
      			<div className="col-md-4">
      		{
     	state.edit && 
          
          <Button className="btn-space" variant="outline-success" onClick={e => handleUpdateProduct()}><i className="fas fa-arrow-circle-up"></i><span className=""> Update Product</span></Button>
      		
      	}
      	</div>
      		</div>
          
          </div>
          
         
    </>
  );


};


