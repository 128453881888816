import React, {useEffect, useState} from "react";
import { Route, Redirect } from "react-router-dom";
import {gql, useQuery} from '@apollo/client'
import { useLazyQuery } from '@apollo/client';
import { useHistory } from "react-router-dom";
import roleManager from './RoleManager'
import Auth from "./auth"
const accessRole = "CUSTOMER"

const isUserQ = gql`
query isUser{
  isUser{
      user{
        firstName
        lastName
        role{
          role
        }
        twoFactorAuth{
          status
        }
      }
      tokenType
    }
  }
`


export const HomeContainer = ({
  component: Component,
  ...rest
}) => {

  let history = useHistory()
  const [isUser, {loading, error, data}] = useLazyQuery(isUserQ)
 

  useEffect(() => {
    
  
    if(Auth.hasActiveToken()){
      isUser()
    }

  }, [isUser]);

  if (data?.isUser.user.role.role === accessRole) {
     var event = new Event('updateCustomerNav');
      document.dispatchEvent(event);
  return(
      <Route
        {...rest}
        render={props => {
         let data = {
         access: true,
         ...props
         }
            return (
              <div>
                <Component {...data} />
              </div>
            );
        }}
      />
     )
  }

  if (data?.isUser.user.role.role !== accessRole && data?.isUser.user.role.role !== undefined) {
  console.log(data)
  return(
    <>
     <Redirect to={roleManager.getStartingRoute(data.isUser.user.role.role)} />
    </> 
    )
  }

     
  

  return (

   <Route
        {...rest}
        render={props => {
       
        let data = {
         access: false,
         ...props
         }
            return (
              <div>
                <Component {...data}  />
              </div>
            );
        }}
      />


  )
 
};



