import {Card, Button, FormControl, Overlay, Popover, ListGroup, ListGroupItem} from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import React, {useState, useRef} from 'react'
import ShoppingCartManager from './ShoppingCartManager'

const server = process.env.REACT_APP_IMAGE_URL
function ProductCard(props) {
   console.log(props) 
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [state, setState] = useState({quantity: "1"})
  const ref = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };    
    
let history = useHistory()
let product = props.product

function loadProduct(e){
    e.preventDefault()
    console.log(e)
  history.push("/product/" + e.target.dataset.id);
}

function addToCart(e){
   e.preventDefault() 
}
    
  function handleQuantityChange(e){
    const value = e.currentTarget.value
    setState(state => {

        return {
        ...state,
        quantity: value
      }
    })
}


function addToCart(e){
   e.preventDefault() 
  console.log("click add to cart")
  let product = {
    id: props.product.id,
    quantity: 1,
    price: props.product.price,
    storeCode: props.vendor.accessCode,
    storeName: props.vendor.name
  }
  ShoppingCartManager.addToShoppingCart(product)
}


    
return(
<>


 <div className="col-mb-4 bottomP">


 <div className="card h-100  bottomP card-spacer text-center zeroBorder " >

  <img onClick={(e) => loadProduct(e)} src={ product.images.length == 0 ? "./blank.png" : server + product.images[0]} height="200px" className=" fhpimage "  data-id= {product.id} alt="..."/> 
     <div className="card-header text-center  secondaryBG zeroBorder  ">
    

<a href="#" className="card-title h-100 primaryText  h6"  data-id= {product.id}  onClick={(e) => loadProduct(e)}> <h6 data-id={product.id}>{product.name}</h6> </a>
      
     </div>
      <div className="card-body secondaryBG zeroP h-100">
      {product.description}
      </div>
      <div className="card-footer container secondaryBG zeroBorder s">
        <div className="row-fluid d-flex justify-content-between ">
        
        <span className="">${product.price.toFixed(2)}</span> 
        

        
        <span className=" ">
          
        {
  product.availableQuantity > 0 ?
    <span>   <Button variant="light" size="sm"  onClick={(e) => addToCart(e)}    >
     
   <i className="bi bi-cart-plus "  > Add to Cart
               
              </i> </Button>     </span>
        :
<Button variant="light" size="sm"  onClick={(e) => addToCart(e)}    >
     
     <i className="bi bi-slash-circle "  > Out of Stock
                 
                </i> </Button>     

}
          
          </span>
      
        </div>
    
        </div>
    </div>



   



  
</div>







</>
);
}


export default ProductCard

