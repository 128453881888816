import React, {useState, useEffect} from "react"
import {Card} from 'react-bootstrap'
import {gql, useQuery} from '@apollo/client'
const orders = gql`
{
    getVendorOrders{
        status
        code
         ... on VendorOrdersResponse{
            vendororders{
            orders{
                id
                address{
                    streetNo
                    streetName
                    district
                    municipality
                }
                customer
                timeStamp
                status
                lookupCode
                productOrder{
                    productId
                    quantity
                    price

                }
                
            }
            
        }
        
    }


  }

}

`
export const OrdersVendorPage = props => {
    const {loading, error, data} = useQuery(orders, {fetchPolicy: "no-cache"})
    if(loading){console.log(loading)}
  
    
    
    const vendorOrders = data?.getVendorOrders?.vendororders?.orders?.map((order, idx) =>
        <React.Fragment>
           
        <OrdersVendorCard order={order} data={props.data} requestcomponent={props.requestcomponent}/>
           
        </React.Fragment>
                                                                       
                                                                       
        )
    
    function goBack(event){
      props.requestcomponent(props.data.sender, { requestcomponent: props.requestcomponent, sender: props.data.previous})
      
    }
  return (
    <>    <Card.Header className="">
          <div className="row ">
            <div className="col-md-4">
            <a href="#" className="forestGreen text-start "> <span onClick={e => goBack(e)} ><i className="fas fa-arrow-left"></i> Back </span> </a>
             </div>
               <div className="col-md-4">
           <h5 className=" text-center darkSlateGray  ">Store Orders</h5>
           </div>
             <div className="col-md-4">
             </div>
           </div>
           </Card.Header>
          <div className="row-fluid">
          
          <hr/>
          {
              data?.getVendorOrders.status ?
              
                <>
                
              <div className="container   ">

              <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 ">
              {vendorOrders}
              </div>
              </div>
              </>
              :
              <p></p>
              
          }
         
          </div>
    </>
  );


};


export const OrdersVendorCard = props => {

    

    
    
    function getTotalItems(){
        let quantity = 0;
        for(var item of props.order.productOrder){
            quantity = quantity + item.quantity
        }
        return quantity
        
    }
    function getTotalPrice(){
        let price = 0;
        for(var item of props.order.productOrder){
            price = (item.price * item.quantity) + price
        }
        return price
        
    }
    function getDate(){
        console.log(parseInt(props.order.timeStamp))
        const event = new Date(parseInt(props.order.timeStamp));
        
        return event.toDateString()
    }
    
    function viewOrder(e){
        props.requestcomponent("Vendor Order", {sender: "Orders", previous: props.data.sender, order: getHolder(),    requestcomponent: props.requestcomponent})
        
    }
    
    function getHolder(){
        
        let idHolder  = []
        for(var product of props.order.productOrder){
            console.log(product)
            idHolder.push(product.productId)
        }
        console.log(idHolder)
        var order = props.order
        order["productIds"] = idHolder
        return order
    }
    
    
  return (
    <>
          <div className="col mb-4 bottomP">
          <div className="card">
          
          <a href="#" onClick={e => viewOrder(e)}   className="">
          
           <Card.Header className=""> <h5 className=" text-center darkSlateGray  ">Order: {props.order.lookupCode}</h5></Card.Header>
        
          <Card.Body>
          <span>Unique Items: {props.order.productOrder.length}</span>
          <p>Total Items: {getTotalItems()}</p>
          <span>Total Price: ${getTotalPrice()}</span>
          <p>{props.order.address != null ? "Delivery" : "PickUp"} </p>
          <span>Ordered on {getDate()}</span>
          
          </Card.Body>
         
          <Card.Footer className="text-center">
          <h6>Status: {props.order.status}</h6>
          </Card.Footer>
          </a>
         </div>
          
     </div>
          
          
    </>
  );


};



