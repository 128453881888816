import React, {useRef, useState} from "react";
import auth from "../auth";
import roleManager from '../RoleManager'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {gql, useMutation} from '@apollo/client'
import { useHistory } from "react-router-dom";

const  twoFactorLoginM = gql `
    mutation twoFactorLogin($code: String!){
      twoFactorLogin(code: $code){
        token
        tokenExpiration
        serverTime
        user{
          firstName
          lastName
          role{
            role
            id
          }
        }

      }
    }
  `
const initialState = {
  code: ''
}

export const TwoFactorLoginPage = props => {
  let history = useHistory()
  if(auth.getToken() === null){
    history.push("/login")
  }
 
  const [state, setState] = useState(initialState)
  const [errors, setErrors] = useState('')

  const [twoFactorLogin, { loading, error, data }] = useMutation(twoFactorLoginM, {
    onCompleted: (data) =>{
      if(data !== undefined ){
        if(data.twoFactorLogin === null){
        }else{
          var clearNetworkCacheEvent = new Event ("clearCache")
          document.dispatchEvent(clearNetworkCacheEvent)
          let authPayload = {
            token: data.twoFactorLogin.token,
            tokenExpiration: data.twoFactorLogin.tokenExpiration,
            firstName: data.twoFactorLogin.user.firstName,
            lastName: data.twoFactorLogin.user.lastName

          }
          auth.setAuth(authPayload)

          loadAccess(data.twoFactorLogin.user.role)
        }
      }

    },
    onError: (error) => console.error("Error", error)
  })

  if(loading){return (<p> Loading... </p>)}

  const handleSubmit = e => {
    e.preventDefault()

    for(let key in state){
      if(state[key] === ''){
        setErrors(`You must provide the input ${key}`)
        console.log(errors)
        return
      }

    }

    setErrors('')
    console.log("before login")

    handleLogin()
  }

  const handleInput = e => {
    if(e.keyCode === 13){
      handleSubmit(e)
    }

    const inputName = e.currentTarget.name
    const value = e.currentTarget.value
    setState(prev => ({...prev, [inputName]: value}))
  }

  async function handleLogin() {
    console.log("in query")
    twoFactorLogin({ variables: {code: state.code } });

  }

  function loadAccess(role){

    if(role.id.length > 0 ){
      history.push(roleManager.getStartingRoute(role.role))
    }else{
      props.requestcomponent("Account Setup", { role: role, requestcomponent: props.requestcomponent})
    }
  }

  return (
    <>

    <div className="full jumbotron">
    <h2 className="text-center">Enter 6 Digit Code</h2>

    <div className="col-md-4 col-md-offset-4">
    { errors && ( <p>{errors}</p>)
    }
    <form noValidate>
    <Form.Group controlId="v">
    <Form.Label>Six Digit Code</Form.Label>
    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
    <Form.Control  size="sm" required type="text" placeholder="" name="code" value={state.code} onChange={handleInput} onKeyDown={handleInput} />   
    </Form.Group>
    <Button size="sm" onClick={e => handleSubmit(e)}>Submit</Button>
    <p></p>


    </form>
    </div>


    </div>
    </>
  );
};

