
import {Form, FormControl, InputGroup, DropdownButton, Button, Jumbotron, Dropdown, Table} from 'react-bootstrap'
import React, {useState, useEffect} from 'react'
import { withRouter } from "react-router";
import {gql, useQuery} from '@apollo/client'
import Container from 'react-bootstrap/Container';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap/';
import { useHistory } from "react-router-dom";
import Projects from './pcarousel.js'
import ShoppingCartManager from './ShoppingCartManager'
import {
  useLocation
} from "react-router-dom";



const vendorProductQ = gql`
query getStoreVendorProduct($id: String!){
  getStoreVendorProduct(id: $id){
    	status
		code
    ... on VendorStoreProductResponse{
    storeproduct{
        product{
            id
            name
            description
            descriptors{
        	    name
        		description
            }
            images
            price
            status
            keywords
            sale
            type
            availableQuantity
            deliveryAvailable
            package{
              length
              width
              depth
              weight
            }
        }
        vendorName
        vendorCode
        relatedProducts{
        id
            name
            description
            descriptors{
        	    name
        		description
            }
            images
            price
            status
            keywords
            sale
            type
            availableQuantity
        
        }

    }
    }
    }
  }

`


const server = process.env.REACT_APP_IMAGE_URL

function ProductPage(props) {
  	const product = props.location.pathname.slice(9)
  	const {loading, error, data, refetch} = useQuery(vendorProductQ,  { fetchPolicy: "no-cache", variables: {id: product}})
    



    return (
        <>
             {
     loading && 
<div id="overlay">

<div className="loader"></div>
    </div>

    }
        
{ data?.getStoreVendorProduct.status &&
	<ProductContainerPage storeProduct={data?.getStoreVendorProduct.storeproduct} />
}


</>
    );
}

const ProductPageWithRouter = withRouter(ProductPage);
export default ProductPage;






const ProductContainerPage= props => {
    console.log(props)
        let history = useHistory()

 const [state, setState] = useState({displayImage: props.storeProduct.product.images[0], quantity: 1})
console.log(state) 
function loadVendor(e){
    e.preventDefault()
    console.log(e)
  history.push("/store/" + e.target.dataset.id);
}
    
const images = props.storeProduct.product.images.map((image, idx) => 
    
    <React.Fragment key={idx}>
         <img id=" " onClick={(e) => changeImage(e)} src={  server + image} width="100%" className="productImageSet"  data-id= {idx} alt="..."/> 

    </React.Fragment>
)

const descriptors = props.storeProduct.product.descriptors.map((descriptor, idx) => 
    
    <React.Fragment key={idx}>
         
    <tr>
     
      <td><h6>{descriptor.name}</h6></td>
                                                              
      <td>{descriptor.description}</td>
  
    </tr>
   
    </React.Fragment>
)

function changeImage(e){
      setState(state => {
    
    return{
      ...state,
      displayImage: props.storeProduct.product.images[e.target.dataset.id]
    }


  })
    
}

  function handleQuantityChange(e){
    var regex=/^[0-9]+$/;
    const value = e.currentTarget.value
    if (value.match(regex)){
    setState(state => {
        
        return {
        ...state,
        quantity: parseInt(value)
      }
    })
    }
}

function addToCart(e){
   e.preventDefault()
    console.log("adding prod")
  let product = {
    id: props.storeProduct.product.id,
    quantity: state.quantity,
    price: props.storeProduct.product.price,
    storeCode: props.storeProduct.vendorCode,
    storeName: props.storeProduct.vendorName
  }
  ShoppingCartManager.addToShoppingCart(product)
}



 
return (
 <>
      
    <div className="bottomP"><br/></div>
 
    <div className="row w80  bottomP   ">


    <div className="col-md-4 col-lg-3 container ">
    <div className="mx-auto d-block ">
<div className="rect-img-container">
  <img className="rect-img" src={server + state.displayImage} alt=""/>
</div>
    </div>     
  <div className="container ">
     <div className="row row-cols-4 row-cols-sm-4 row-cols-md-3 row-cols-lg-4">
    {images} 
    </div>
        </div>
    </div>
        
<div className="col-md-8">
    <div className="container productNameBG bottomP">
        <h5 className="fontSmallCaps  text-center darkSlateGrey"> {props.storeProduct.product.name}</h5>
    </div>
<div className="container">

        <div className="row ">
  <div className="col-sm-6 col-md-6 noPM order-2">
        <h6 className="">Product Description</h6> 
        <span className="f400">{props.storeProduct.product.description}   </span>
   
  </div>
<div className="col-md-6 noPM order-1">
        <h6 className=" f400">Sold By:<a href="#"   data-id= {props.storeProduct.vendorCode} onClick={(e) => loadVendor(e)}> {props.storeProduct.vendorName}</a> </h6>
  

      <InputGroup.Text id="inputGroup-sizing-lg" ><b> ${props.storeProduct.product.price.toFixed(2)}</b></InputGroup.Text>
    



  <div className="row-fluid">
  <div className="col-xs-6 col-md-10 noPM">

  { props.storeProduct.product.availableQuantity > 0 ? 
<>
   <InputGroup className="md-10">
 <FormControl
  className="inputSizeControl"
    type="number"
    min="1"
    name="quantity" value={state.quantity} onChange={handleQuantityChange} 
 onKeyDown={() => {return false}}
    />
<Button variant="success" size="sm" onClick={(e) => addToCart(e)}>
     
   <i className="fas fa-cart-plus "> Add to Cart
              </i> </Button>

  </InputGroup>
 </>
:

  <p>  Out of Stock</p>


  }


  </div>
  </div>
  </div>
        </div>
</div>
  <div className="row-fluid topP">
 <h6 className="f400">Product Information</h6>
        <Table striped bordered hover size="sm">
  <thead>
    <tr>
    
    
    </tr>
  </thead>
  <tbody>

            {descriptors}
  </tbody>
</Table>
  </div>  

  <div className='row-fluid topP'>
 <h6 className="f400">Product Dimensions</h6>
<Table striped bordered hover size="sm">
  <thead className="">
    <tr className="f400">
      <th className="f400">Lenght - Inches</th>
      <th className="f400">Width - Inches</th>
      <th className="f400">Depth - Inches</th>
      <th className="f400">Weight - Pounds</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{props.storeProduct.product.package.length.toFixed(2)}</td>
      <td>{props.storeProduct.product.package.width.toFixed(2)}</td>
      <td>{props.storeProduct.product.package.depth.toFixed(2)}</td>
      <td>{props.storeProduct.product.package.weight.toFixed(2)}</td>
    </tr>
 
  </tbody>
</Table>

  </div>

    </div>   
    </div>

   <div className="bottomP"></div>
    <div className="row text-center productNameBG ">
        <h6 className="f400">Related Products</h6>
</div>
 
     <Projects products={props.storeProduct.relatedProducts} />
 



</>
        
)
}




