import React, {useState, useEffect, useRef} from 'react'
import {Card, Breadcrumb, InputGroup, Button, Popover, OverlayTrigger, Tooltip, Nav} from 'react-bootstrap'
import {gql, useQuery, useMutation, useLazyQuery} from '@apollo/client'
import ShoppingBagManager from './ShoppingCartManager'
import ShoppingBagUpdate from './ShoppingCartUpdate'
import { useHistory } from "react-router-dom";

let localStorageKey = "shoppingCart"


export function ShoppingCart (props) {
  let history = useHistory()
    var data = []
    if(localStorage.getItem('shoppingCart') !== ""){
       data = JSON.parse(localStorage.getItem('shoppingCart')) || []
    }
  const [value, setValue] = React.useState(data);
   const [state, setState] = useState(() => {
  //let data = JSON.parse(localStorage.getItem('shoppingCart')) || [];
    let counter = 0 
    for(const product of data){
      counter = counter + product.quantity
    }
    return {cart: counter}
   })
  const [updated , setUpdated] = React.useState(false)

  document.addEventListener("itemInserted", updatedCart, false);

  function cartUpdated(){
    console.log("setting")
    let count = ShoppingBagManager.getCount()
    if((count % 2) == 1)  {

      document.getElementById("shopBag").style.animationName = "example"
    }else{

      document.getElementById("shopBag").style.animationName = "example2"
    }

    setValue(JSON.parse(localStorage.getItem('shoppingCart')) || [])
  }

  function changeCart(count){
      setState(state => {
    
    return{
      ...state,
      cart: count
    }


  })
    
}


  function updatedCart(){
    let data = []
      if(localStorage.getItem('shoppingCart') !== ""){
          data = JSON.parse(localStorage.getItem('shoppingCart')) || [];
      }
    let counter = 0 
    for(const product of data){
      counter = counter + product.quantity
    }
    changeCart(counter)

  }



  function getCartData(){

    var data = []
      if(localStorage.getItem('shoppingCart') !== ""){
          data = JSON.parse(localStorage.getItem('shoppingCart')) || [];
      }
    let total = 0
    let counter = 0
    let products = 0
    for(const product of data){
      total = total + (product.quantity * product.price)
     counter = counter + product.quantity
    products++
    }

    return (
      <React.Fragment>
      <ShoppingCartData total={total.toFixed(2)} counter={counter} products={products}/>
      </React.Fragment>
    )
  }


  const renderTooltip = (props) => (
    <Tooltip   {...props}>
    {getCartData()}
    </Tooltip>
  );


  function loadCart(){
    history.push("/cart");
  }



  return(
    <>

    <OverlayTrigger
    placement="bottom"
    delay={{ show: 250, hide: 400 }}
    overlay={renderTooltip}
    >
    <Nav.Link href="#"  onClick={loadCart}><span className="navIcon navLinkText "> <i className="bi bi-cart f5">  </i>{state.cart  != 0 ? state.cart : "" } </span></Nav.Link>
    </OverlayTrigger>







    </>
  )
}


function ShoppingCartData (props) {

  return(
    <>
    <div className="container  text-center ">
    <div className="row-fluid text-center">
    <span className=" text-center">{props.products} {props.products == 1 ? "Unique Product" :  "Unique Products"} </span> 
    <p></p>
    <span className=" text-center">{props.counter} {props.counter == 1 ? "Item Total " :  "Items Total "} </span>

    <p></p>
    <h6>Total: $ {props.total}</h6>
    </div>
    </div>


    <div >




    </div>


    </>
  )
}







