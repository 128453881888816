import React, {useState, useEffect} from 'react'
import {gql, useQuery, useMutation, useLazyQuery} from '@apollo/client'
import {Tab, Nav, Row, Col, ListGroup} from 'react-bootstrap'
import Components from "./Components"
import ComponentManager from './components/index'

let CM = new ComponentManager()
const currentApp = {app: ["Welcome"], component: CM.getComponent("Welcome").app, 
  props: {
  }
}



function AccountPage(props) {
  console.log(props)
let components = new Components()
let tags = components.getComponents(props.account.role.role)
  const [appState , setAppState] = useState({tags: tags})
  const [currentAppState, setCurrentAppState] = useState(currentApp)
  console.log(appState)

  const changeAppState = async (name, properties) => {

    let CM = new ComponentManager()
    setCurrentAppState(curentAppState => {
      let component = CM.getComponent(name)
      if(component != false){
        return {
          app: [component.name],
          component: component.app,
          props: properties
        }
      }
      else{
        return {
          ...currentAppState
        }
      }
    })
  }


 const tagsMenu = appState.tags.map((tag, idy) =>
    <React.Fragment key={idy}>
  {tag.status ? 
  <ListGroup.Item  className="navBlue navLightText text-center" active="true"  onClick={loadApp} data-id={tag.name} data-idy={idy}>{tag.name}</ListGroup.Item>
:
   <ListGroup.Item className="blueText" action  onClick={loadApp} data-id={tag.name} data-idy={idy}>{tag.name}</ListGroup.Item>
  }
      </React.Fragment>

  )




  function loadApp(event){
    let tagset = appState
    for(var x=0; x< tagset.tags.length ; x++ ){
      if(tagset.tags[x].status){
        console.log("should remove")
          tagset.tags[x].status = false
      }
    }
    tagset.tags[event.target.dataset.idy].status = true
    console.log(tagset)
   // setAppState(tagset)
      setAppState(state => {

        return {
          ...state,
          tags: tagset.tags
        }
      })
   changeAppState(event.target.dataset.id, currentApp.props)

  }

  function loadAppWithProps(name, properties){
    console.log("Loading with Props")
    changeAppState(name, properties)
  }


  const app = currentAppState.app.map((name, idy) =>

    <React.Fragment key={name}>
    <currentAppState.component user={props.account } data={currentAppState.props} requestcomponent={(x,y) => loadAppWithProps(x,y)}/>

    </React.Fragment>
  )


  return (
    <>

    <div className="jumbotron parent sVM whiteBG noP">
    
    <div className="row ">

    <div className="col-md-2 bottomP ">
    <div className="boxFilter boxShadow ">
    <div className="row-fluid">

 

  
    <ListGroup>
  <ListGroup.Item className="text-center fontSmallCaps primaryGText "><h5>Account <i className="fas fa-user-circle"></i> </h5></ListGroup.Item>

    {tagsMenu}
    </ListGroup>
    </div>
    </div>
    </div>

    <div className="col-md-9">
    <div className="jumbotron parent whiteBG boxShadow noP"> 
    {app}
    </div>
    </div>

    </div>


    </div>

    </>
  );



}

export default AccountPage;

