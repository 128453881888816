import React, {useRef, useState} from "react";
import Toast from 'react-bootstrap/Toast'
import Form from 'react-bootstrap/Form'
import {Col, Row, Button, Badge, Card, ListGroup, Image} from 'react-bootstrap/'
import {gql, useMutation} from '@apollo/client'

const  addProductM = gql `
    mutation addProduct($product: ProductInput!){
      addProduct(product: $product){
        status
        code
        ... on AcknowledgedResponse{
          data
        }
      }
    }
  `


var initialState = {
  name: '',
  description: '',
  images: [],
  availableQuantity: 0,
  price: 0.0,
  descriptors: [],
  status: "true",
  sale: "",
  type: "",
  keywords: [],
  keywordholder: "",
  deliveryAvailable: false, 
  length: "",
  width: "",
  depth: "",
  weight: "",
}

const resetState = {
  name: '',
  description: '',
  images: [],
  availableQuantity: 0,
  price: 0.0,
  descriptors: [],
  status: true,
  sale: "",
  type: "",
  keywords: [],
  keywordholder: "",
  deliveryAvailable: false,
  length: "",
  width: "",
  depth: "",
  weight: "",

}



export const AddProductPage = props => {
  console.log(props)
  const [state, setState] = useState(initialState)
  const [errors, setErrors] = useState('')


  console.log(parseFloat(parseFloat(state.length).toFixed(2)).toFixed(2))
  // setState(prev => ({...prev, hasDepartment: props.hasDept, departmentID: props.deptID}))

  const [addProduct, { loading, error, data }] = useMutation(addProductM, {
    onCompleted: (data) =>{
      if(data !== undefined ){
        console.log(data)
        if(data.addProduct === null){
        }else{
          if(!data.addProduct.status){
            return (<p> Product not added </p>)
          }
          initialState = resetState
          setState(initialState)


        }

      }

    },
    onError: (error) => console.error("Error", error)
  })






  function viewProduct(productID){
    console.log(props)
    props.requestComponent("View Product", {id: productID, requestComponent: props.requestComponent})
  }

  async function handleCreateProduct() {


    addProduct({ variables: {product: {
      name: state.name,
      description: state.description,
      images: state.images,
      availableQuantity: parseInt(state.availableQuantity),
      price: parseFloat(parseFloat(state.price).toFixed(2)),
      descriptors: state.descriptors,
      keywords: state.keywords,
      sale: state.sale,
      type: state.type,
      status: (state.status === "true"),
      deliveryAvailable: (state.deliveryAvailable === "true"),
      package: {
        length: parseFloat(parseFloat(state.length).toFixed(2)),
        width: parseFloat(parseFloat(state.width).toFixed(2)),
        depth: parseFloat(parseFloat(state.depth).toFixed(2)),
        weight: parseFloat(parseFloat(state.weight).toFixed(2))
      }
      
    }} });
  }

  const handleSubmit = e => {
    e.preventDefault()

    for(let key in state){
      if(state[key] === '' && key !== "keywordholder"){
        setErrors(`You must provide the input ${key}`)
        console.log(errors)
        return
      }

    }
    setErrors('')

    handleCreateProduct()
  }


  const handleInput = e => {
    const inputName = e.currentTarget.name
    const value = e.currentTarget.value
    //  console.log(e.target.value)
    if(["descriptorsName"].includes(e.target.dataset.classname)){
      let descriptorsList = state.descriptors
      descriptorsList[e.target.dataset.id][e.target.dataset.control] = e.target.value

      setState(state => {

        return {
          ...state,
          descriptors: descriptorsList
        }
      })
    }
    else{
      setState(prev => ({...prev, [inputName]: value}))
    }
  }

  function addProductDescriptor(e){
    e.preventDefault()
    setState(state => {
      const descriptorsList = state.descriptors.concat({
        name: "",
        description: ""
      })
      return {
        ...state,
        descriptors: descriptorsList
      }
    })
  }


  function addKeyword(e){
    e.preventDefault()
    setState(state => {
      const keywordsList = state.keywords.concat(state.keywordholder.toLowerCase().trim())
      return {
        ...state,
        keywords: keywordsList,
        keywordholder: ""
      }
    })
  }

  function removeKeyword(e){
    setState(state => {
      var keywordsList = state.keywords
      keywordsList.splice(e.target.dataset.id, 1)
      return {
        ...state,
        keywords: keywordsList
      }
    })
  }

  const keywordsList = state.keywords.map((value, idx) =>
    <React.Fragment  key={idx}>
    <div className="col mb-4 bottomP">
    <Badge pill variant="success">{value}</Badge>
    <i className="btn-space fa fa-times-circle redText" onClick={e => removeKeyword(e)} aria-hidden="true" data-id={idx} ></i> 
    </div>
    </React.Fragment>

  )

  const descriptorsName = "descriptorsName"
  const descriptorsList = state.descriptors.map((value, idx) =>

    <React.Fragment key={idx}>
    <ListGroup.Item className="list-group-flush">
    <div className="row">
    <Col sm={4}>
    <Row>
    <Col sm={1}>
    <i className="btn-space fa fa-times-circle redText" aria-hidden="true" data-id={idx} onClick={e => removeProperty(e)}></i> 
    </Col>
    <Col sm={2}>

    <Form.Label> Name</Form.Label>
    </Col>
    <Col sm={8}>
    <Form.Control size="sm" type="text" name={descriptorsName.concat(idx)} data-id={idx} data-control="name" id={descriptorsName.concat(idx)} value={state.descriptors[idx].property}  data-classname={descriptorsName}  onChange={handleInput}
    placeholder="Descriptor Name"/>
    </Col>
    </Row>

    </Col>
    <Col sm={8}>
    <Row>
    <Col sm={2}>
    <Form.Label>Description</Form.Label>
    </Col>
    <Col sm={10}>
    <Form.Control size="sm" type="text" name={descriptorsName.concat(idx)} data-id={idx} data-control="description" id={descriptorsName.concat(idx)} value={state.descriptors[idx].value}  data-classname={descriptorsName}  onChange={handleInput}
    placeholder="Description"/>
    </Col>
    </Row>
    </Col>

    </div>

    </ListGroup.Item>
    </React.Fragment>
  )


  function loadImage(e){
    var reader = new FileReader();
    reader.onload = function(){
      // var output = document.getElementById('output');
      // output.src = reader.result;
      // console.log(reader.result.split(';base64,').pop())
      // console.log("sending product")
      addProductImage(e, reader.result)
    };
    reader.readAsDataURL(e.target.files[0]);
    //console.log(reader)

  }

  function addProductImage(e, image){
    e.preventDefault()
    // console.log("receive ")
    // console.log(image)
    setState(state => {
      const productImagesList = state.images.concat(image)
      return {
        ...state,
        images: productImagesList
      }
    })
  }

  const productImageList = state.images.map((value, idx) =>
    <React.Fragment  key={idx}>
    <div className="col mb-4 bottomP">
    <img width="150px" src={value} className="topP" />
    <i className="btn-space fa fa-times-circle redText" aria-hidden="true" data-id={idx} onClick={e => removeImage(e)}></i> 

    </div>
    </React.Fragment>
  )

  function removeImage(e){
    console.log(e.target.dataset.id)
    document.getElementById("productImages").value = null 
    setState(state => {
      var productImagesList = state.images
      productImagesList.splice(e.target.dataset.id, 1)
      return {
        ...state,
        images: productImagesList
      }
    })
  }

  function removeProperty(e){
    setState(state => {
      var descriptorsList = state.descriptors
      descriptorsList.splice(e.target.dataset.id, 1)
      return {
        ...state,
        descriptors: descriptorsList
      }
    })
  }


  function onValueChange(event) {

    setState(state => {
      return {
        ...state,
        status: event.target.value
      }
    })

  }

  function onValueChangeType(event) {

    setState(state => {
      return {
        ...state,
        type: event.target.value
      }
    })

  }


  function onValueChangeSale(event) {

    setState(state => {
      return {
        ...state,
        sale: event.target.value
      }
    })

  }


  function onValueChangeDelivery(event) {

    setState(state => {
      return {
        ...state,
        deliveryAvailable: event.target.value
      }
    })

  }


  function clearUpdate(){
    document.getElementById("productImages").value = null 
    initialState = resetState
    setState(initialState)
  }

  function goBack(event){
    props.requestcomponent(props.data.sender, { requestcomponent: props.requestcomponent})

  }


  return (
    <>

    {
      loading && 
      <div id="overlay">

      <div className="loader"></div>
      </div>

    }


    <div className=" bottomP">
    <Card.Header className="">
    <div className="row ">
    <div className="col-md-4">
    <a href="#" className="forestGreen text-start "> <span onClick={e => goBack(e)} ><i className="fas fa-arrow-left"></i> Back </span> </a>
    </div>
    <div className="col-md-4">
    <h5 className=" text-center darkSlateGray  ">Add Product to Store</h5>
    </div>
    <div className="col-md-4">
    </div>
    </div>
    </Card.Header>
    </div>
          <hr/>
    <div className="container redText">
    { errors && ( <p>{errors}</p>)
    }
    </div>
    <div className="row-fluid w80  ">



    <form noValidate>
    <Row>




    </Row>
    <Form.Group as={Row} className="mb-3"  >
    <Form.Label column sm={2}>Product Name</Form.Label>
    <Col sm={10}>
    <Form.Control  size="sm" required type="text" placeholder="Product Name" name="name" value={state.name} onChange={handleInput} />
    </Col>

    <Form.Label column sm={2}>Product Description</Form.Label>
    <Col sm={10}>
    <Form.Control className="bottomP" as="textarea" rows={3} placeholder="Description" name="description" value={state.description} onChange={handleInput} />
    </Col>
    <Form.Label column sm={2}>Available Quantity</Form.Label>
    <Col sm={4}>
    <Form.Control  size="sm" required type="number" placeholder="Quantity" name="availableQuantity" value={state.availableQuantity} onChange={handleInput} />
    </Col>
    <Col sm={2}> Price $</Col>
    <Col sm={4}>
    <Form.Control className="bottomP" size="sm" required type="number" placeholder="Price" name="price" value={state.price} onChange={handleInput} />
    </Col>

    <div className="container-fluid row">
    <Button className="bottomP" variant="outline-dark" onClick={e => addProductDescriptor(e)}><i className=" fa fa-plus-circle" aria-hidden="true"></i> <span className="">Add Descriptor</span></Button>

    <ListGroup>
    {descriptorsList}
    </ListGroup>




    </div>








    </Form.Group>
    </form>



    </div>


    <div className="container bottomP">


    <span>Product Images (Use Square Images for better viewing as images will be squared)</span>


    <Form.Control className="bottomP" id="productImages" type="file" size="sm" onChange={e => loadImage(e)}/>

    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5">


    {productImageList}


    </div>

    <ListGroup.Item className="list-group-flush">
    <div className="row bottomP">
    <div className="col-md-4"> Product Active in Store</div>
    <div className="col-md-4">
    <input
    type="radio"
    value="true"
    checked={state.status === "true"}
    onChange={onValueChange}
    />
    <label className="sRP">
    Active
    </label>

    </div>


    <div className="col-md-4">
    <input
    type="radio"
    value="false"
    checked={state.status === "false"}
    onChange={onValueChange}
    />
    <label className="sRP">
    Inactive
    </label>

    </div>

    </div>
    </ListGroup.Item>

    <ListGroup.Item className="list-group-flush">
    <div className="row bottomP">

    <div className="col-md-4"> Product Type</div>
    <div className="col-md-4">
    <input
    name="type"
    type="radio"
    value="FOOD"
    checked={state.type === "FOOD"}
    onChange={onValueChangeType}
    />
    <label className="sRP">
    Food
    </label>

    </div>



    <div className="col-md-4">
    <input
    name="type"
    type="radio"
    value="SUPPLIES"
    checked={state.type === "SUPPLIES"}
    onChange={onValueChangeType}
    />
    <label className="sRP">
    Supplies
    </label>

    </div>

    </div>
    </ListGroup.Item>

    <ListGroup.Item className="list-group-flush">
    <div className="row bottomP">
    <div className="col-md-4"> Product Sale Type</div>
    <div className="col-md-4">
    <input
    type="radio"
    value="RETAIL"
    checked={state.sale === "RETAIL"}
    onChange={onValueChangeSale}
    />
    <label className="sRP">
    Retail
    </label>

    </div>


    <div className="col-md-4">
    <input
    type="radio"
    value="WHOLESALE"
    checked={state.sale === "WHOLESALE"}
    onChange={onValueChangeSale}
    />
    <label className="sRP">
    Wholesale
    </label>

    </div>

    </div>
    </ListGroup.Item>







    <ListGroup.Item className="list-group-flush">
    <div className="row bottomP">
    <div className="col-md-4"> Delivery Available</div>
    <div className="col-md-4">
    <input
    type="radio"
    value="true"
    checked={state.deliveryAvailable === "true"}
    onChange={onValueChangeDelivery}
    />
    <label className="sRP">
    Available
    </label>

    </div>


    <div className="col-md-4">
    <input
    type="radio"
    value="false"
    checked={state.deliveryAvailable === "false"}
    onChange={onValueChangeDelivery}
    />
    <label className="sRP">
    Unavailable
    </label>

    </div>

    </div>
    </ListGroup.Item>



    <ListGroup.Item>
    <div className="row bottomP">
    <div className="col-md-4">
    <label className="">
    Product Dimensions In Inches and Pounds (Needed for Delivery)
    </label>

    </div>
    <div className="col-md-2">
    <Form.Control  size="sm" required type="number" placeholder="Length" name="length" value={state.length} onChange={handleInput}  onKeyDown={() => {return false}}   />

    </div>
    <div className="col-md-2">
    <Form.Control  size="sm" required type="number" placeholder="Width" name="width" value={state.width} onChange={handleInput} />


    </div>
    <div className="col-md-2">
    <Form.Control  size="sm" required type="number" placeholder="Depth" name="depth" value={state.depth} onChange={handleInput} />


    </div>
    <div className="col-md-2">
    <Form.Control  size="sm" required type="number" placeholder="Weight" name="weight" value={state.weight} onChange={handleInput} />


    </div>

    </div>

    </ListGroup.Item>



    <ListGroup.Item>
    <div className="row bottomP">
    <div className="col-md-4">
    <label className="">
    Main Search Keywords | Menu and SubMenu
    </label>

    </div>
    <div className="col-md-4">
    <Form.Control  size="sm" required type="text" placeholder="Keyword" name="keywordholder" value={state.keywordholder} onChange={handleInput} />

    </div>
    <div className="col-md-4">
    <Button className="bottomP" variant="outline-dark" onClick={e => addKeyword(e)}><i className=" fa fa-plus-circle" aria-hidden="true"></i> <span className="">Add </span></Button>

    </div>
    </div>
    <div className="row row-cols-3 row-cols-sm-2 row-cols-md-3 row-cols-lg-5">


    {keywordsList}


    </div>
    </ListGroup.Item>




    </div>

    <div className="row w80 bottomP">
    <Col sm={5}>
    <Button className="btn-space" variant="outline-danger" onClick={e => clearUpdate()}><i className=" fa fa-trash" aria-hidden="true"></i><span className=""> Clear</span></Button>
    </Col>
    <Col sm={7}>
    <Button className="btn-space" variant="outline-success" onClick={e => handleSubmit(e)}><span className="">Add Product</span></Button>
    </Col>
    </div>

    </>
  );
};


