import {Button } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import {gql, useQuery} from '@apollo/client'
import React from 'react'


const featuredProducts = gql`
{
    getFeaturedVendors{
        status
        code
        ... on VendorStoresResponse{
            vendors{
                id
                name
                accessCode
                profileImage
                storeImageBanner
                profileSlogan
                address{
                    streetNo
                    streetName
                    district
                    municipality


                }
            }
        }
    }

}


`



const server = process.env.REACT_APP_IMAGE_URL
function FeaturedVendor(props) {
let history = useHistory()
console.log(props.data)
    const {loading, error, data} = useQuery(featuredProducts, {fetchPolicy: "no-cache"})
    if(loading){console.log(loading)}
    console.log(data)


function loadStore(e){
  
  e.preventDefault()
  history.push("/store/" + e.target.dataset.id);

}
    

    
const vendors = data?.getFeaturedVendors?.vendors?.map((vendor, idx) =>
<React.Fragment key={idx}>


<div className="card h-100  bottomP card-spacer text-center zeroBorder " >
                                                          <a href="#" data-id={vendor?.accessCode} onClick={(e) => loadStore(e)}><img src={server + vendor?.profileImage} className=" fhpimage " alt="...."  height="200px"  data-id={vendor?.accessCode}/> </a>
<div className="card-header text-center  secondaryBG zeroBorder  ">
<h6 className="card-title h-100 primaryText ">{vendor?.name}</h6>

</div>
<div className="card-body secondaryBG zeroP">
                                                      
            <span className=" text-center">"{vendor?.profileSlogan}"</span>
                                                       


<p className="card-text   ">{vendor?.address.streetNo + ", " + vendor?.address.streetName}</p>
                                                       
                                                       <p className="card-text   ">{vendor?.address.municipality + ", " + vendor?.address.district}</p>
</div>
<div className="card-footer container secondaryBG zeroBorder ">


</div>
</div>

  </React.Fragment>
                                                          
)
    

return(
<>
       <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 ">
       {vendors}
    </div>

</>
);
}


export default FeaturedVendor




