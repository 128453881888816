class Locations {

districts = [
  "ORANGEWALK", "COROZAL", "BELIZE","CAYO", "STANNCREEK", "TOLEDO"

]

municipalities = ["Orange Walk Town", "Belize City", "Corozal Town", "San Ignacio", "Santa Elena",
  "Belmopan City", "San Pedro Town", "Caye Caulker", "Ladyville", "Dangriga", "Punta Gorda"]


districtMatch = {
  "Orange Walk Town" : "ORANGEWALK",
  "Belize City" : "BELIZE",
  "Corozal Town" : "COROZAL",
  "San Ignacio" : "CAYO",
  "Santa Elena" : "CAYO",
  "Belmopan City" : "CAYO",
  "San Pedro Town" : "BELIZE",
  "Caye Caulker" : "BELIZE",
  "Ladyville" : "BELIZE",
  "Dangriga" : "STANNCREEK",
  "Punta Gorda" : "TOLEDO"

}

districtMatchControl = {
  "Orange Walk" : "ORANGEWALK",
  "Belize" : "BELIZE",
  "Corozal" : "COROZAL",
  "Cayo" : "CAYO",
  "Belize" : "BELIZE",
  "Stann Creek" : "STANNCREEK",
  "Toledo" : "TOLEDO"

}

municipalityMatch = {
  "Orange Walk Town" : "ORANGEWALKTOWN",
  "Belize City" : "BELIZECITY",
  "Corozal Town" : "COROZALTOWN",
  "San Ignacio" : "SANIGNACIO",
  "Santa Elena" : "SANTAELENA",
  "Belmopan City" : "BELMOPANCITY",
  "San Pedro Town" : "SANPEDROTOWN",
  "Caye Caulker" : "CAYECAULKER",
  "Ladyville" : "LADYVILLE",
  "Dangriga" : "DANGRIGA",
  "Punta Gorda" : "PUNTAGORDA"

}





  getMunicipalityMatch(){
    return this.municipalityMatch
  }

  getDistrictMatchControl(){
    return this.districtMatchControl
  }

  getDistrictMatch(){
    return this.districtMatch
  }

  getMunicipalities(){
    return this.municipalities
  }

  getDistricts(){
    return this.districts
  }
  
}

export default Locations

