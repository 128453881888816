import {Jumbotron, Button, Card, Form, Toast} from 'react-bootstrap'
import {gql, useMutation, useQuery} from '@apollo/client'
import React, {useRef, useState} from "react";
import Locations from '../../Locations'
const customerAddresses = gql`
{
  getCustomerAddresses{
    status
    code
    ... on CustomerAddressesResponse{
        customeraddresses{
          addresses{
              streetNo
              streetName
              district
              municipality
          }
          primaryAddress
        }
    }
  }
}
`

const  addAddressM = gql `
    mutation addCustomerAddress($address: AddressInput!){
      addCustomerAddress(address: $address){
        status
        code
        ... on AcknowledgedResponse{
        	data
      	}
      }
    }
  `

const  updateCustomerPrimaryAddressM = gql `
    mutation  updateCustomerPrimaryAddress($marker: Int!){
      updateCustomerPrimaryAddress(marker: $marker){
        status
        code
        ... on AcknowledgedResponse{
        	data
      	}
      }
    }
  `

  
export const CustomerAddressesPage = props => {
const {loading, error, data, refetch} = useQuery(customerAddresses, {fetchPolicy: "no-cache"})
  function reload(){
    refetch()
  }
  return (
    <>
    {
      loading && 
      <div id="overlay">

      <div className="loader"></div>
      </div>

    }
    <div className="row-fluid bottomP ">
          <br/>
    <Card.Header className="">
    <div className="row ">
         
    <div className="col-md-4">
    </div>
    <div className="col-md-4">
          
    <h5 className=" text-center darkSlateGray">Addresses</h5>
    </div>
          <hr/>
    <div className="col-md-4">
    </div>
    </div>
    </Card.Header>
    </div>

    <div className="container">
    {
      !loading &&

      <div className="container ">
      <CustomerAddressesDataPage addresses={data?.getCustomerAddresses.customeraddresses.addresses} primaryAddress={data?.getCustomerAddresses.customeraddresses.primaryAddress} reload={() => reload()}/>
      </div>
    }

    </div>

    </>
  );


};


const initialState = {
  streetNo: "",
  streetName: "",
  municipality: "",
  district: ""
}

const resetState = {
  streetNo: "",
  streetName: "",
  municipality: "",
  district: ""
}

let locations = new Locations()


export const CustomerAddressesDataPage = props => {

  
const [show, setShow] = useState(false);
  const [state, setState] = useState(initialState)
  const [errors, setErrors] = useState('')




  const [addCustomerAddress, { loading, error, data }] = useMutation(addAddressM, {
    onCompleted: (data) =>{
      if(data !== undefined ){
       
        if(data.addCustomerAddress === null){
        }else{
            if(!data.addCustomerAddress.status){
             return (<p> Addressnot Updated </p>)

          }
            setShow(true)
            setState(resetState) 
            props.reload()
        }

      }

    },
    onError: (error) => console.error("Error", error)
  })
  


 const [updateCustomerPrimaryAddress, { loading: loadingU, error: errorU, data: dataU }] = useMutation(updateCustomerPrimaryAddressM, {
    onCompleted: (dataU) =>{
      if(dataU !== undefined ){
     
        if(dataU.updateCustomerPrimaryAddress === null){
        }else{
            if(!dataU.updateCustomerPrimaryAddress.status){
             return (<p> Primary Address not Updated </p>)

          }
            props.reload()
        }

      }

    },
    onError: (error) => console.error("Error", error)
  })




  function getObjectKey(obj, value) {
    return Object.keys(obj).find((key) => obj[key] === value);
  }

 const handleAddressInput = e => {
    const inputName = e.currentTarget.name
    const value = e.currentTarget.value
          setState(prev => ({...prev, [inputName]: value}))
  }
  
   const handleMunicipalityInput = e => {
    const inputName = e.currentTarget.name
    const value = e.currentTarget.value
      setState(state => {
    
      return {
        ...state,
        	municipality: locations.getMunicipalityMatch()[value],
        	district: locations.getDistrictMatch()[value]
      }
    })
  }
    const municipalityTypes = locations.getMunicipalities().map((municipality, idx) => 
    <option key={idx}>{municipality}</option>
    )

  function handleAddAddress(){
    addCustomerAddress({variables: {address: {
      streetNo: parseInt(state.streetNo),
      streetName: state.streetName,
      municipality: state.municipality,
      district: state.district

    }}})
  }


  function handleUpdatePrimaryAddress(e){
    e.preventDefault()

    updateCustomerPrimaryAddress({variables: {
      marker: parseInt(e.target.dataset.id)
    }})
  }



  const handleSubmit = e => {
    e.preventDefault()

    for(let key in state){
      if(state[key] === ''){
        setErrors(`You must provide the input ${key}`)
        return
      }

    }
    setErrors('')

    handleAddAddress()
  }


  const addresses = props.addresses.map((address, idx) => 
    <React.Fragment key={idx}>
    <div className="col-mb-4 bottomP">
    <div className="card  text-center  card-spacer" >
    <Card.Header></Card.Header>
    <span>  <h6 className="card-title f400 topP">{address.streetNo} , {address.streetName}</h6></span>

    <span className="card-text ">{getObjectKey(locations.getMunicipalityMatch(),address.municipality)}</span>
    <p className="card-text ">{getObjectKey(locations.getDistrictMatchControl(), address.district)}</p>

    <Card.Footer className="">
    {props.primaryAddress == idx ? "Primary Address"  :  
      <Card.Link href="#" ><Button variant="outline-success" size="sm"  data-id={idx} onClick={(e) => handleUpdatePrimaryAddress(e)}>
      Make Primary</Button></Card.Link>  
    }
    </Card.Footer>
    </div>
    </div>

    </React.Fragment>

  )

  return(
    <>
    {       loading && 
      <div id="overlay">

      <div className="loader"></div>
      </div>

    }
    <div className="row">
    <div className="col-md-8"></div>
    <div className="col-md-4">
    <Toast onClose={() => setShow(false)} show={show} delay={5000} autohide>
    <Toast.Header>
    <i className="fas fa-check-circle forestGreen"></i>
    <strong className="mr-auto"> Vendor Space</strong>
    <small>Just now</small>
    </Toast.Header>
    <Toast.Body>Address Added</Toast.Body>
    </Toast>
    </div>
    </div>
    <div className="container bottomP text-center">
    { errors && ( <p>{errors}</p>)
    }
         <br/>
    <h6>Address</h6>
   
    <div className="row  ">

    <div className="col-md-4 bottomP">
    <Form.Control  size="sm" required type="number" placeholder="Street No" name="streetNo" value={state.streetNo} onChange={handleAddressInput} />
    </div>
    <div className="col-md-4 bottomP">
    <Form.Control  size="sm" required type="text" placeholder="Street Name" name="streetName" value={state.streetName} onChange={handleAddressInput} />
    </div>
    <div className="col-md-4 bottomP">
    <Form.Control as="select" name="municipality" value={getObjectKey(locations.getMunicipalityMatch(), state.municipality)} onChange={handleMunicipalityInput}>
    { !state.municipality	?	
      <option hidden >Select Municipality</option>
      : ""
    }
    {municipalityTypes}
    </Form.Control>
    </div>

    </div>


    </div>

    <div className="row-fluid text-center">
    <Button className="btn-space" variant="outline-success" onClick={(e) => handleSubmit(e)}><i className="fas fa-plus-circle"></i><span className=""> Add Address</span></Button>
    </div>
    <hr/>

    <div className="container">
    <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">

    {addresses}

    </div>

    </div>


    </>
  )

}
