import React, {useRef, useState, useEffect} from "react";
import ListGroup from 'react-bootstrap/ListGroup'
import {Button, Table, Card, FormControl, InputGroup} from 'react-bootstrap'
import {gql, useQuery} from '@apollo/client'

const products = gql`
{
  getActiveVendorProducts{
    status
	code
    ... on ProductsResponse{
        products{
            id
            name
            description
            descriptors{
        	    name
        		description
            }
            images
            price
            status
            keywords
            sale
            type
            availableQuantity
            vendor
package{
              length
              width
              depth
              weight
            }

        }
    }
  }
}
`


 const server = process.env.REACT_APP_IMAGE_URL

const initialState= {
  search: '',
  products: [],
  filteredProducts: []
}


export const ActiveVendorProductsPage = props => {
console.log(props)
  const {loading, error, data} = useQuery(products, {fetchPolicy: "no-cache"})

 
  const [state, setState] = useState(initialState) 
useEffect(() => {
    if(loading){

    }else{
      setState(state => {

        return {
          ...state,
          products: data?.getActiveVendorProducts?.products,
          filteredProducts: data?.getActiveVendorProducts.products
        }
      })
    }

}, [data]) 
 




function viewProduct(event){
  console.log(event.target.dataset.id)
  props.requestcomponent("View Product", {sender: "Active Products", previous: props.data.sender, product: state.filteredProducts[event.target.dataset.idx] , requestcomponent: props.requestcomponent})
  
}

const onKeyDown = e =>{
      if (e.keyCode === 8 && state.search !== '') {
             console.log('delete');

              setState(state =>{

                        return {
                                    ...state,
                                    search: e.value,
                                  }
                      })

            }
    }


const handleSearchInput = e => {
  e.preventDefault()
  console.log(e.target) 

  if(e.keyCode !== 8 ){
    //searchChangeState(e.target.value)

    setState(state => {


      return{
        ...state,
        search: e.target.value,
      }


    })

  }
}


function searchChangeState(search){
  setState(state => {
    let searchFilter = search.toUpperCase().trim()
    let products = []

    for(const prod of state.products){
      if(prod.name.toUpperCase().indexOf(searchFilter) > -1 || prod.description.toUpperCase().indexOf(searchFilter) > -1 || prod.name.replace(/\s+/g, '').toUpperCase().indexOf(searchFilter) > -1 || prod.description.replace(/\s+/g, '').toUpperCase().indexOf(searchFilter) > -1){
        products = products.concat(prod)
      }
    }

    return{
      ...state,
      filteredProducts: products
    }


  })
}



const productsList = state.filteredProducts.map((product, idx) =>
  <React.Fragment key={product.id}>
 <div className="col mb-4 bottomP">
  <div className="card   topP">

  <a href="#" onClick={e => viewProduct(e)} data-id= {product.id} className="h6 ">
  <div className="" data-id= {product.id} data-idx = {idx}>
  <img src={ product.images.length == 0 ? "./blank.png" : server + product.images[0]} height="150px" className=" fhpimage card-img-top" data-idx = {idx} data-id= {product.id} alt="..."/> 
  </div>
  </a>
<a href="#" onClick={e => viewProduct(e)} data-idx = {idx} data-id= {product.id} className="h6 "> <h6 data-idx = {idx} data-id={product.id}>{product.name}</h6> </a>
   
   <p className="card-text  productDescription">{product.description}</p>

    <Card.Footer  style={product.status ? {background: "green"} : {background: "red"}}>


<b className="text-start text-white "> {"$" + product.price}</b> 



  </Card.Footer>

  </div> 
</div>

  </React.Fragment>
)


function goBack(event){
  props.requestcomponent(props.data.sender, { requestcomponent: props.requestcomponent})
  
}

return (
  <>
  {
     loading && 
<div id="overlay">

<div className="loader"></div>
    </div>

    }
    <div className=" bottomP">
               <Card.Header className="">
           <div className="row ">
             <div className="col-md-4">
             <a href="#" className="forestGreen text-start "> <span onClick={e => goBack(e)} ><i className="fas fa-arrow-left"></i> Back </span> </a>
          	</div>
          	  <div className="col-md-4">
            <h5 className=" text-center darkSlateGray  ">Active Store Products</h5>
            </div>
              <div className="col-md-4">
              </div>
            </div>
            </Card.Header>
       
          </div>
        <hr/>
   <SearchBar search={(x) => searchChangeState(x)} />
        <br/>
  <div className="container text-center   ">

  <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
  {productsList}

  </div>
  </div>
  </>
);


};











const searchState = {
  search: ''
}
function SearchBar(props){

  const [state, setState] = useState(searchState) 


  function search(){
    props.search(state.search)
  }

const onKeyDown = e =>{
      if (e.keyCode === 8 && state.search !== '') {
             console.log('delete');

              setState(state =>{

                        return {
                                    ...state,
                                    search: e.value,
                                  }
                      })

            }
    }


const handleSearchInput = e => {
  e.preventDefault()
  console.log(e.target) 

  if(e.keyCode !== 8 ){
    //searchChangeState(e.target.value)

    setState(state => {


      return{
        ...state,
        search: e.target.value,
      }


    })

  }
}



  return(
 <div className="container text-center">
  <InputGroup  className="">
  <FormControl
  placeholder=""
  aria-label="search input"
  aria-describedby="basic-addon2"
  onChange={handleSearchInput}
  onKeyDown={onKeyDown}
  value={state.search}
  />
  <Button  variant="outline-success"

  onClick={search}
  >Search</Button>
  </InputGroup>
  </div>

  )
}
