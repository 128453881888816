import ProductCard from './productcard'
import {Form, FormControl, InputGroup, DropdownButton, Button, Jumbotron, Dropdown} from 'react-bootstrap'
import React, {useState, useEffect} from 'react'
import { withRouter } from "react-router";
import {gql, useQuery} from '@apollo/client'
import Container from 'react-bootstrap/Container';
import {Nav, Navbar, NavDropdown} from 'react-bootstrap/';
import { useHistory } from "react-router-dom";
import {
  useLocation
} from "react-router-dom";



const vendorProductsQ = gql`
query getStoreVendorProducts($id: String!){
  getStoreVendorProducts(id: $id){
    	status
		code
    ... on VendorStoreResponse{
    vendorstore{
      vendor{
		products
		profileImage
		profileSlogan
		storeImageBanner
		name
		approved
		accessCode
		address{
			streetNo
			streetName
			municipality
			district
		}
		storeMenus{
			menuName
			subMenus
			status
		}
      }
      products{
			id
            name
            description
            descriptors{
        	    name
        		description
            }
            images
            price
            status
            keywords
            sale
            type
            availableQuantity
            vendor
      }
    }
    }
    }
  }

`



const districtMatchControl = {
  "Orange Walk" : "ORANGEWALK",
  "Belize" : "BELIZE",
  "Corozal" : "COROZAL",
  "Cayo" : "CAYO",
  "Cayo" : "CAYO",
  "Cayo" : "CAYO",
  "Belize" : "BELIZE",
  "Belize" : "BELIZE",
  "Belize" : "BELIZE",
  "Stann Creek" : "STANNCREEK",
  "Toledo" : "TOLEDO"

}

const municipalityMatch = {
  "Orange Walk Town" : "ORANGEWALKTOWN",
  "Belize City" : "BELIZECITY",
  "Corozal Town" : "COROZALTOWN",
  "San Ignacio" : "SANIGNACIO",
  "Santa Elena" : "SANTAELENA",
  "Belmopan City" : "BELMOPANCITY",
  "San Pedro Town" : "SANPEDROTOWN",
  "Caye Caulker" : "CAYECAULKER",
  "Ladyville" : "LADYVILLE",
  "Dangriga" : "DANGRIGA",
  "Punta Gorda" : "PUNTAGORDA"

}



const server = process.env.REACT_APP_IMAGE_URL
function StoresPage(props) {
  
  	const vendor = props.location.pathname.slice(7).toUpperCase()
  	
  	const {loading, error, data, refetch} = useQuery(vendorProductsQ,  { fetchPolicy: "no-cache", variables: {id: vendor}})
   

    return (
        <>
  {
     loading && 
<div id="overlay">

<div className="loader"></div>
    </div>

    }
    <div className="container AppBodyController ">
{ data?.getStoreVendorProducts.status &&
	<StoreContainerPage vendorstore={data?.getStoreVendorProducts.vendorstore} />
}

</div>
</>
    );
}

const StoresPageWithRouter = withRouter(StoresPage);
export default StoresPage;


const initialState= {
  search: '',
  products: [],
  filteredProducts: []
}

const StoreContainerPage= props => {
    let history = useHistory()
  
	let bg = server + props.vendorstore.vendor.storeImageBanner
	  const [menuOpen, toggleMenuOpen] = useState(false);
	  const [state, setState] = useState({
	  	products: props.vendorstore.products,
	  	filteredProducts: props.vendorstore.products,
	  	vendor: props.vendorstore.vendor
	  	}
	  ) 
  console.log(state)

const products =  state.filteredProducts.map((product, idx) => 
    
    <React.Fragment key={idx}>
      <div className="col mb-4">
        <ProductCard product={product} vendor={state.vendor}/>
   </div>

    </React.Fragment>
    )
    
    function searchChangeState(search){
  setState(state => {
    let searchFilter = search.toUpperCase().trim()
    let products = []

    for(const prod of state.products){
    console.log(prod)
      if(prod.name.toUpperCase().indexOf(searchFilter) > -1 || prod.description.toUpperCase().indexOf(searchFilter) > -1 || prod.name.replace(/\s+/g, '').toUpperCase().indexOf(searchFilter) > -1 || prod.description.replace(/\s+/g, '').toUpperCase().indexOf(searchFilter) > -1){
        products = products.concat(prod)
      }
    }

    return{
      ...state,
      filteredProducts: products
    }


  })
}

  const storeMenusNav = props.vendorstore.vendor.storeMenus.map((value, idx) =>
    <React.Fragment  key={idx}>
 <NavDropdown className="primaryText" title={<span className="primaryText">{value.menuName.toUpperCase()}</span>} id="navbarScrollingDropdown">
       <NavDropdown.Item  onClick={() => searchMenu(value.menuName)}><b> {value.menuName}</b></NavDropdown.Item>
        <NavDropdown.Divider />
		{showSubMenusNav(value.subMenus)}
      </NavDropdown>

    </React.Fragment>

)

function showSubMenusNav(subMenus){
	return subMenus.map((value, idx) =>
    <React.Fragment  key={idx}>
 <NavDropdown.Item onClick={() => searchSubMenu(value)}>{value}</NavDropdown.Item>
    </React.Fragment>
    )
}

function searchMenu(menu){
	let search = menu
    setState(state => {
    let searchFilter = search.toLowerCase().trim()
    let products = []
    let controlledProducts = JSON.parse(JSON.stringify(state.products));
    

    for(var x=0; x< controlledProducts.length; x++ ){
    	
    	let status = controlledProducts[x].keywords.includes(searchFilter, 0)
    	if(status){
    	products = products.concat(controlledProducts[x])
        controlledProducts.splice(x, 1)
       
    	}
    }
     
    
    const subMenus = getSubMenuList(searchFilter)
    for(const prod of controlledProducts){
    	
    	let status = subMenus.filter(element => prod.keywords.indexOf(element.toLowerCase().trim()) !== -1)
    	if(status.length > 0){
    	products = products.concat(prod)	
    	}
    }
       
        var holder = products.map(JSON.stringify)
        var holderSet = new Set(holder);
        var uniqueProducts = Array.from(holderSet).map(JSON.parse);

    return{
      ...state,
      filteredProducts: uniqueProducts
    }


  })
	
}
function searchSubMenu(menu){
	console.log("search sub menu")
    let search = menu
	console.log(menu)
	
    setState(state => {
    let searchFilter = search.toLowerCase().trim()
    console.log(searchFilter)
    let products = []

    for(const prod of state.products){
    	
    	let status = prod.keywords.includes(searchFilter, 0)
    	console.log(status)
    	if(status){
    	products = products.concat(prod)	
    	}
    }
    
    return{
      ...state,
      filteredProducts: products
    }


  })
}

function getSubMenuList(menu){
    for(const storeMenu of state.vendor.storeMenus){
       // const menuName = storeMenu.menuName
        if(menu.toLowerCase().trim() === storeMenu.menuName.toLowerCase().trim()){
            return storeMenu.subMenus
        }
    }
}


  function sortAscendingName(){
    let products = state.filteredProducts
    products.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

     setState(state => {
             return {
        ...state,
        filteredProducts: products
      }
    })
   
  }

  function sortDescendingName(){
     let products = state.filteredProducts
    products.sort((a,b) => (a.name < b.name) ? 1 : ((b.name < a.name) ? -1 : 0))

     setState(state => {
             return {
        ...state,
        filteredProducts: products
      }
    })

  }

  function sortLowestPrice(){
     let products = state.filteredProducts
    products.sort((a,b) => (a.price > b.price) ? 1 : ((b.price > a.price) ? -1 : 0))

     setState(state => {
             return {
        ...state,
        filteredProducts: products
      }
    })

  }

  function sortHighestPrice(){
    let products = state.filteredProducts
    products.sort((a,b) => (a.price < b.price) ? 1 : ((b.price < a.price) ? -1 : 0))

     setState(state => {
             return {
        ...state,
        filteredProducts: products
      }
    })
  }

 function getObjectKey(obj, value) {
  return Object.keys(obj).find((key) => obj[key] === value);
}

    function clickMainLogo(){
        searchChangeState("")
    }

// <li class="breadcrumb-item active" aria-current="page">Library</li>
return (
 <>

<div className="row-fluid    ">


   <Navbar  bg="" expand="lg" variant="light" className=" whiteBG primaryText mx-auto ">
  <Navbar.Brand href="#">

 <img
        onClick={clickMainLogo}
   		className=""
        src={server + state.vendor.profileImage}
        width="100"
        height="100"
        className="d-inline-block align-top"
        alt="logo"
      />
  
  </Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" data-toggle="collapse" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="mr-auto ">
      {storeMenusNav}
   
    </Nav>
  
    <Nav className="me-auto">
    </Nav>
       <SearchBar search={(x) => searchChangeState(x)} name={state.vendor.name}/> 
  
  </Navbar.Collapse>
</Navbar>



</div>
        <hr/>
<div className="row flex-column-reverse flex-lg-row">
        <div className="col-md-3 row-fluid text-center ">
        
        
              <div className="container centerFilter  ">
              

      <hr/>
        <h5 className="text-center">{state.vendor.name}</h5>
      <h6 className="text-center">Address</h6>
      <h6 className="text-center f400 darkSlateGrey topP textWrap">{state.vendor.address.streetNo} {state.vendor.address.streetName}, {getObjectKey(municipalityMatch, state.vendor.address.municipality)}, {getObjectKey( districtMatchControl,  state.vendor.address.district)}</h6>


        <hr/>
        <DropdownButton
        className=""
      variant="outline-success"
      title="Sort By"
      id="sort"
    >
      <Dropdown.Item href="#" onClick={sortLowestPrice}>Lowest Price</Dropdown.Item>
      <Dropdown.Item href="#" onClick={sortHighestPrice}>Hisgest Price</Dropdown.Item>
      <Dropdown.Item href="#" onClick={sortAscendingName}>Ascending Name</Dropdown.Item>
      <Dropdown.Item href="#" onClick={sortDescendingName}>Descending Name</Dropdown.Item>
  
    
    </DropdownButton>
          <div className="jumbotron  text-center  verticalAdSpace textWrap"></div>
         
          </div>
            </div>
        
        <div className="col-md-9  ">
           <div className="container-fluid text-center">
        <img className="img-fluid mx-auto d-block " src={server + state.vendor.storeImageBanner} />
        </div>
        </div>
        
</div>

<hr />

 
        <div className="row ">


<div className="col-md-12  ">
   <div className="container-fluid text-center">
<div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-5">

     {products}
</div>
</div>
</div>
</div>

        
</>
        
)
}



const searchState = {
  search: ''
}

function SearchBar(props){

  const [state, setState] = useState(searchState) 


  function search(){
    props.search(state.search)
  }

const onKeyDown = e =>{
//console.log(e.keyCode)
      if (e.keyCode === 8 && state.search !== '') {
             console.log('delete');

              setState(state =>{

                        return {
                                    ...state,
                                    search: e.value,
                                  }
                      })

            }
            
        if(e.keyCode === 13){
        	search()
        }    
    }


const handleSearchInput = e => {
  e.preventDefault()
  //console.log(e.target) 

  if(e.keyCode !== 8 ){
    //searchChangeState(e.target.value)

    setState(state => {


      return{
        ...state,
        search: e.target.value,
      }


    })

  }
}



  return(
 <div className=" ">
  <InputGroup   className="">
  <FormControl
  placeholder={"Search Products" }
  aria-label="search input"
  aria-describedby="basic-addon2"
  onChange={handleSearchInput}
  onKeyDown={onKeyDown}
  value={state.search}
  className=""
  />
  <Button  variant="outline-dark"
  onClick={search}>Search</Button>
  </InputGroup>
  </div>

  )
}
