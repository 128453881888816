import { useHistory } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";
export const RedirectLoginPage = props => {
let history = useHistory()
    history.push("/login");
  return (
    <>
          <div className="row-fluid">

           </div>

     
    </>
  );


};
