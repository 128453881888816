import React from 'react'
import auth from './auth'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import {Col, Navbar, Nav, Form, FormControl, NavDropdown,DropdownButton, Dropdown, InputGroup, Container} from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import {useState, useRef, useEffect} from 'react'
import { Route, Redirect } from "react-router-dom";
import logo from './img/everyday.png'
import {ShoppingCart} from './stores/ShoppingCart'
import {AllVendorsMenu} from './allvendors'

const initialState = {
  loggedIn: false,
  customer: false, 
  location: "Shop By Department"
}
export const NavBar = props => {
  console.log(props) 
  let history = useHistory()

  const [state, setState] = useState(initialState)
  useEffect(() => {
    document.addEventListener("loggedIn", loggedIn, false)
    document.addEventListener("logout", logout, false)
    document.addEventListener("clearNavbarData", clearNavbarData, false)
    document.addEventListener("updateCustomerNav", updateCustomerNav, false)


  }, []);

  const [navChange, setNavChange] = useState(true);

  const changeNavbar = () =>{
    console.log("scrolling")
     if(window.scrollY >= 80){
      setNavChange(false);
     }
     else{
      setNavChange(true);
     }
  };
  window.addEventListener('scroll', changeNavbar);


  function updateCustomerNav(){
    setState(state => {
      return {
        ...state,
        customer: true
      }
    })

  }

  const redirectLogin = e => {
    history.push("/redirectLogin");

  }

  const redirectRegister = e => {
    history.push("/register");

  }
  const redirectHome = e => {
    history.push("/");

  }

  function clearNavbarData(){
    setState(state => {

      return {
        ...state,
        loggedIn: false
      }

    })

  }



  function logout(){
    console.log("loggin out")
    auth.removeAuth(() => {
      history.push("/logout");

    });

    setState(state => {

      return {
        ...state,
        loggedIn: false
      }

    })

  }

  function loggedIn(){


    setState(state => {

      return {
        ...state,
        loggedIn: true
      }

    })

  }



  function loadRegisterPartner(){
    history.push("/registerPartner");
  }


  function loadAccount(){
    history.push("/account");
  }

  const displayHeader = ()=>{

    if(auth.getToken() !== null ){
      if(state.customer){

        return(
          <>
             
          <NavDropdown title={auth.getName()} id="" className="">
          <NavDropdown.Item href="#" onClick={loadAccount} className="navRightO">Account</NavDropdown.Item>
          <NavDropdown.Item href="#" onClick={logout} className="navRightL">Logout</NavDropdown.Item>
          </NavDropdown>
          <Nav className=" mr-auto navLightText zeroP">

          <ShoppingCart />

          </Nav>



          </>
        )

      }else{
        return (
          <>
                    
          <NavDropdown title={auth.getName()} id="" className="">
          <NavDropdown.Item href="#" onClick={loadAccount} className="">Account</NavDropdown.Item>
          <NavDropdown.Item href="#" onClick={logout} className="">Logout</NavDropdown.Item>
          </NavDropdown>
                &nbsp;  &nbsp;  &nbsp;
               
          </>
        )
      }
    }
    else{
      return (
        <>
             
        <Nav className=" mr-auto d-flex align-items-baseline navLinkText">
        <Nav.Link href="#" onClick={redirectLogin}><span className="navLinkText ">Login <i className="bi bi-person navIcon"></i></span></Nav.Link>
              <Nav.Link href="#" onClick={loadRegisterPartner}><span className="navLinkText">Sell on Market Place <i className="bi bi-shop"></i> </span></Nav.Link>
     
        <ShoppingCart />
        </Nav>
        </>
      )
    }
  }





  function searchChangeState(search){

  }

  const locationDropDown = locations.map((location, idx) => 
<Dropdown.Item key={idx} className=""  href="#" value={idx} onClick={e => setLocation(e)} >{location}</Dropdown.Item>
)

function setLocation(e){
  console.log(e.target.getAttribute("value"))
  setState(state =>{

    return {
      ...state,
      location: locations[e.target.getAttribute("value")],
    }
  })
}

    
    function getAllVendors(){
        
        if(auth.getToken() !== null ){
            if(state.customer){
                return (
                        <div className=""> <AllVendorsMenu /> </div>
                        )
            }
            
        }else{
            return (
                    <div className=""> <AllVendorsMenu /> </div>
                    )
        }
    }

  return (
    <>



<div className="container-fluid navContainer text-center   navTitleText mx-auto ">
  <div className="row   topNav ">
    <Navbar sticky="top" expand="sm"   className={navChange ? ' d-flex justify-content-between ' : ' d-flex justify-content-between zeroP'} >
    <Navbar.Brand className="">
    <a href="/" onClick={redirectHome}> <img src={logo} className="float-left" alt="logo" width="110px" /></a>

    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav" className=" ">
          {getAllVendors()}
    <Nav className="me-auto d-flex align-items-baseline">
          
    </Nav>
   
    {displayHeader()}

    </Navbar.Collapse>
    </Navbar>
    </div>

   
   
  

   

    
    </div>


    
    </>
  )
}



const locations = ["Orange Walk Town", "Belize City", "Corozal Town", "San Ignacio", "Santa Elena",
 "Belmopan City", "San Pedro Town", "Caye Caulker", "Ladyville", "Dangriga", "Punta Gorda", "All Departments"]

const searchState = {
  search: '',
  location: "All Departments"
}

function SearchBar(props){

  const [state, setState] = useState(searchState) 


  function search(){
    props.search(state.search)
  }

  const locationDropDown = locations.map((location, idx) => 
<Dropdown.Item key={idx}  href="#" value={idx} onClick={e => setLocation(e)} >{location}</Dropdown.Item>
)

function setLocation(e){
  console.log(e.target.getAttribute("value"))
  setState(state =>{

    return {
      ...state,
      location: locations[e.target.getAttribute("value")],
    }
  })
}

  const onKeyDown = e =>{
    //console.log(e.keyCode)
    if (e.keyCode === 8 && state.search !== '') {
      console.log('delete');

      setState(state =>{

        return {
          ...state,
          search: e.value,
        }
      })

    }

    if(e.keyCode === 13){
      search()
    }    
  }


  const handleSearchInput = e => {
    e.preventDefault()
    //console.log(e.target) 

    if(e.keyCode !== 8 ){
      //searchChangeState(e.target.value)

      setState(state => {


        return{
          ...state,
          search: e.target.value,
        }


      })

    }
  }



  return(



    <InputGroup  className=" navSearch mobileHidden ">
     
        <DropdownButton
      className=""
      title={<span className="">{state.location}</span>}
      id="locationDropDown"
      align="start"
      variant="outline-dark"
    >
    {locationDropDown}
    </DropdownButton>

    
    <FormControl
    className="searchInput "
    placeholder=" "
    onChange={handleSearchInput}
    onKeyDown={onKeyDown}
    name= 'search'
    value={state.search}
    />
    <Button  className="navSearchButton" variant="primary"
    onClick={search}><i className="bi bi-search"></i></Button>

    </InputGroup>



  )
}


// <SearchBar search={(x) => searchChangeState(x)} /> 




