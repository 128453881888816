import React, {useEffect, useState} from "react";
import { Route, Redirect } from "react-router-dom";
import {gql, useQuery} from '@apollo/client'
import { useLazyQuery } from '@apollo/client';
import { useHistory } from "react-router-dom";
import {NoAccessPage} from "../noaccess"
import {VerifyEmailPage} from "../account/components/verifyEmail"
import Auth from "../auth"
const accessRole = "VENDOR"

const isUserQ = gql`
query isUser{
  isUser{
      user{
      	email
        firstName
        lastName
        emailVerified
        role{
          role
          id
        }
        twoFactorAuth{
        status
        }
      }
      tokenType
    }
  }
`

const getPartnerAccountByIdQ = gql`
  query getPartnerAccountById{
    getPartnerAccountById{
      status
      code
      ... on PartnerAccountResponse {
        partneraccount{
          approved
          id
          name
        }
      }
    }
  }
`

export const VendorContainer = ({
  component: Component,
  ...rest
}) => {

  let history = useHistory()
  const [isUser, {loading, error, data}] = useLazyQuery(isUserQ)
  const [getPartnerAccountById, {loading: loadingM, error: errorM, data: dataM}] = useLazyQuery(getPartnerAccountByIdQ)

  useEffect(() => {
    if(Auth.hasActiveToken()){
      isUser()
      getPartnerAccountById()
    }else if(Auth.hasExpiredToken()){
      Auth.logout()
    }
    
  }, [isUser]);

  if(loading){return (<p> Loading... </p>)}

  if(!Auth.hasToken()){
    return(
      <>
     <Redirect to={"/login"} />
      </>
    )
  }

  if (data?.isUser.user.role.role === accessRole  && (data?.isUser.user.role.id.length > 0 )) {
    if(Auth.hasAccess(data.isUser.user.twoFactorAuth, data.isUser.tokenType)){
      let account = {role: data.isUser.user.role.role, emailVerified: data.isUser.user.emailVerified, email: data.isUser.user.email}  
      console.log("Account")
      console.log(account)
      if(data.isUser.user.emailVerified){
        if(dataM?.getPartnerAccountById.status){
          if(dataM.getPartnerAccountById.partneraccount.approved){
            return (
              <Route
              {...rest}
              render={props => {
                return (
                  <div>
                  <Component user={account} partneraccount={dataM.getPartnerAccountById.partneraccount}/>
                  </div>
                );
              }}
              />
            );


          }
        }
      }else{
        return (
          <Route
          {...rest}
          render={props => {
            return (
              <div>
              <VerifyEmailPage user={account} />
              </div>
            );
          }}
          />
        );
      }


    }



  }




  return(
    <>
    <NoAccessPage /> 
    </>
  )

};


