import auth from "./auth"

class RoleManager {
  constructor(){
    this.routes = {
      CUSTOMER: "/",
      FARMOWNER: "/farmowner",
      VENDOR:"/vendor"
    }
  }

  getStartingRoute(role){
    return this.routes[role]
  
  }
  
}

export default new RoleManager()
