class Components {
  components = {
    "CUSTOMER": 
    [
{
        name: "Orders",
        status: false
      },
     
      {
        name: "Addresses",
        status: false
      }
      

    ],
    "VENDOR" : 
    [

      {
        name: "Settings",
        status: false,
      },
      {
        name: "Verify Email",
        status: false
      }

    ],

    "FARMOWNER" : 
    [

      {
        name: "Prescriptions",
        status: false,
      }
    ]

  }

  getComponents(account){
    return this.components[account]
  }
}

export default Components 

