
import { useHistory } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";
export const LogoutPage = props => {
let history = useHistory()
    history.push("/login");
  return (
    <>
          <div className="row-fluid">
 <h3 className="text-center">Logging Out
    </h3>

           </div>

     
    </>
  );


};


