import {Button } from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import {gql, useQuery} from '@apollo/client'
import React from 'react'


const featuredProducts = gql`
{
    getFeaturedProducts{
        status
        code
        ... on ProductsResponse{
            products{
                id
                name
                description
                images
                price
                availableQuantity
            }
        }
    }

}


`



const server = process.env.REACT_APP_IMAGE_URL
function FeaturedMPCard(props) {
let history = useHistory()
console.log(props.data)
    const {loading, error, data} = useQuery(featuredProducts, {fetchPolicy: "no-cache"})
    if(loading){console.log(loading)}
    console.log(data)


    
    function loadProduct(e){
      
      e.preventDefault()
      history.push("/product/" + e.target.dataset.id);

    }
    
const products = data?.getFeaturedProducts?.products?.map((product, idx) =>
<React.Fragment key={idx}>


<div className="card h-100  bottomP card-spacer text-center zeroBorder " >
                                                          <a href="#" data-id={product?.id} onClick={(e) => loadProduct(e)}><img src={server + product?.images[0]} className=" fhpimage " alt="...."  height="200px"  data-id={product?.id}/> </a>
<div className="card-header text-center  secondaryBG zeroBorder  ">
<h6 className="card-title h-100 primaryText ">{product?.name}</h6>

</div>
<div className="card-body secondaryBG zeroP">

{product?.description}


</div>
<div className="card-footer container secondaryBG zeroBorder ">
<div className="row">
<div className="col-12">
<span className=" text-center">${parseFloat(product?.price).toFixed(2)}</span>
</div>


</div>

</div>
</div>

  </React.Fragment>
                                                          
)
    
    function getProduct(){
        if(data?.getFeaturedProducts?.status){
           
        }
        
       
    }
return(
<>
       <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 ">
       {products}
    </div>

</>
);
}


export default FeaturedMPCard



