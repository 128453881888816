export const NoAccessPage = props => {
  return (
    <>
          <div className="row-fluid">
          <br/>
 <h3 className="text-center">No Access!</h3>
          <hr/>
          <h5 className="text-center">Your Account is under going approval</h5>
         
          </div>
    </>
  );


};


