import React, {useState, useEffect} from 'react'
import { gql } from '@apollo/client'
import InputGroup from 'react-bootstrap/InputGroup'
import auth from './auth'
import {Button} from 'react-bootstrap'
import { useLazyQuery } from '@apollo/client';
import FeaturedMPCard from './featuredmp'
import FeaturedVendor from './featuredvendor'
import Category from './category'
import DesignatedArea from './DesignatedArea'
import DealDeadline from './deadline'
import { useHistory } from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel';
const featuredmp = [1]
const featuredprod = [1]
function HomePage(props) {
  console.log(props)



let history = useHistory()
  const FeaturedVendorCards =  featuredmp.map((location, idx) =>

    <React.Fragment key={idx}>
    <div className="col mb-4">
    <FeaturedVendor data={idx} control={0}/>
    </div>

    </React.Fragment>
  )
  
    const FeaturedProd =  featuredprod.map((location, idx) => 

    <React.Fragment key={idx}>
    <div className="col mb-4">
    <FeaturedMPCard data={idx} control={1}/>
    </div>

    </React.Fragment>
  )
  





  function loadRegisterPartner(){
  history.push("/registerPartner");
}

async function getDealDeadline(){
  const date = new Date();
   const utcStr = date.toLocaleString('en-US', { timeZone: 'America/Belize' })

 
      const h = padTo2Digits(23 - date.getHours())
      const m = padTo2Digits(59 - date.getMinutes())
      const s = padTo2Digits(59 - date.getSeconds())
   
    var time ="Expiring:" + h + ":" + m + ":" + s + " "
    document.getElementById("MyClockDisplay").innerText = time;
    document.getElementById("MyClockDisplay").textContent = time;
    
    setTimeout(getDealDeadline, 1000);
    
  
}


function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}


/* Ad placement on homepage ?
  <div className="row-fluid container text-center p-2"> 
      <div className="   ">
      <img src="./cmbanner.png" class="d-block w-100" alt="..."/>
      </div>
     
      
      </div>
*/

    function loadVendor(e){
        e.preventDefault()
        history.push("/store/" + e.target.dataset.id)
    }
    
  return (
    <>
<div className="AppBodyController">
     

      <div className=" container-fluid ">
      <div className="row">
        <div className="col-lg-12">
          
      <div id="carouselExampleFade" class="carousel slide carousel-fade" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
          <a href="#" data-id="VK8P6A" onClick={(e) => loadVendor(e)}>  <img src="./banner1.png" data-id="VK8P6A" class="d-block w-100" alt="..."/> </a>
    </div>
    <div class="carousel-item">
          <a href="#" data-id="VAA057" onClick={(e) => loadVendor(e)}>  <img src="./banner.png" data-id="VAA057" class="d-block w-100" alt="..."/> </a>
    </div>
    <div class="carousel-item">
          <a href="#" data-id="VW46OP" onClick={(e) => loadVendor(e)}>  <img src="./banner2.png" data-id="VW46OP" class="d-block w-100" alt="..."/> </a>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
     </div>
  
     
      </div>
    
 </div>

       

    <div className="container-fluid  topP">
    <h6 className="text-center fontSmallCaps darkSlateGray ">Featured Products</h6>
    <hr/>
   

    {FeaturedProd}


    </div>

      
   


    <div className="container-fluid  ">
    <h6 className="text-center fontSmallCaps darkSlateGray ">Featured Vendors</h6>
    <hr/>
    <div className="topP">
   
    {FeaturedVendorCards}


    </div>
    </div>


  










    </div>
    </>
  );
}

export default HomePage;



/*

    <div className="row  d-flex justify-content-around sPad w80 ">

    <div className="card farmCardHeader  bottomP  cardShadow">
    <div className="card-body">
    <p className="card-text  fontSmallCaps text-center darkSlateGray ">Farm Land Rental <i className="fas fa-shipping-fast"></i> </p>
    <p className="card-title fontLato darkGreenColor">Rent a piece of Land </p>
    <p className="card-title fontLato text-center darkGreenColor">Grow Crops at Low Cost</p>
    </div>
    </div>

  

    <div className="card farmCardHeader   bottomP  cardShadow">
    <div className="card-body">
    <p className="card-text  fontSmallCaps text-center darkSlateGray ">Secure Payment <i className="fas fa-money-check-alt"></i> </p>

    <p className="card-title fontLato darkGreenColor">Bank Transfer | Cash</p>
    <p className="card-title fontLato darkGreenColor">Debit Card | Credit Card</p>

    </div>
    </div>
    
      <div className="card farmCardHeader  bottomP  cardShadow">
    <div className="card-body text-center">
<p className="card-text  fontSmallCaps text-center darkSlateGray ">Fast Delivery <i className="fas fa-shipping-fast"></i> </p>
    <p className="card-title fontLato darkGreenColor">Your Farming Supplies Delivered </p>
    <p className="card-title fontLato text-center darkGreenColor">Where You Need</p>
    </div>
    </div>

</div>


    <div className="row d-flex justify-content-around w80">
    <a href="/digitalpharmacy"><Button variant="" className="sMargin cardShadow  farmHomeButton">Farming Rental Properties  </Button></a>
    <a href="/digitalpharmacy">  <Button variant="" className="sMargin cardShadow farmHomeButton">Buy Produce and Meats  </Button></a>
    <a href="/digitalpharmacy"><Button variant="" className="sMargin cardShadow farmHomeButton">Buy Farming Supplies  </Button></a>

    </div>


*/

