import React, {useEffect, useState} from "react";
import { Route, Redirect } from "react-router-dom";
import {gql, useQuery} from '@apollo/client'
import { useLazyQuery } from '@apollo/client';
import { useHistory } from "react-router-dom";
import roleManager from './RoleManager'
import Auth from "./auth"

const isUserQ = gql`
query isUser{
  isUser{
      user{
        firstName
        lastName
        role{
          role
          id
        }
        emailVerified
        twoFactorAuth{
         status
        }
      } 
      tokenType
    }
  }
`


export const LoginContainer = ({
  component: Component,
  ...rest
}) => {

  let history = useHistory()
  const [isUser, {loading, error, data}] = useLazyQuery(isUserQ)
 

  useEffect(() => {
    if(Auth.hasActiveToken()){
      isUser()
    }else if(Auth.hasExpiredToken()){
      Auth.logout()
    }

  }, [isUser]);


  if(!Auth.hasToken()){
    return(
      <Route
        {...rest}
        render={props => {
            return (
              <div>
                <Component {...props} />
              </div>
            );
        }}
      />
    )
  }
  
  
  if (data?.isUser.user.role.role) {
    
    console.log(data)
    if(Auth.hasAccess(data.isUser.user.twoFactorAuth, data.isUser.tokenType)){


    if(data.isUser.user.role.id.length > 0){
   return(
    <>
     <Redirect to={roleManager.getStartingRoute(data.isUser.user.role.role)} />
    </>
    )

    }else{

      return(
        <Route
        {...rest}
        render={props => {
          return (
            <div>
            <Component   hasAccess={true} role={data.isUser.user.role.role}/>
            </div>
          );
        }}
        />
      )

    }





    }
    else{
      if((data.isUser.user.twoFactorAuth) && (!data.isUser.tokenType)){
        return(
          <Route
          {...rest}
          render={props => {
            return (
              <div>
              <Component   tfLogin={true}/>
              </div>
            );
          }}
          />
        )

      }
        Auth.removeAuthData()
        return(
          <Route
          {...rest}
          render={props => {
            return (
              <div>
              <Component {...props} />
              </div>
            );
          }}
          />
        )
    }
  }

  return null

};


