class Components {
  components = [

    {
      name: "Add Product",
      status: false,
    },
    {
      name: "All Products",
      status: false,
    },
    {
      name: "Active Products",
      status: false,
    },
    {
        name: "Orders",
        status: false
    },
    
    {
      name: "Store Menu",
      status: false,
    },
     {
      name: "Vendor Profile",
      status: false,
    }

  ]

  getComponents(){
    return this.components
  }
}

export default Components 

