import React, { useEffect, useState } from "react";


function DealDeadline(){

  return(
    <DealDeadlineTimer />

  )
}

function DealDeadlineTimer() {

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  const calculateTimeLeft = () => {

    let timeLeft = {};

    const date = new Date();
    const utcStr = date.toLocaleString('en-US', { timeZone: 'America/Belize' })
 
  
       const h = padTo2Digits(23 - date.getHours())
       const m = padTo2Digits(59 - date.getMinutes())
       const s = padTo2Digits(59 - date.getSeconds())
    
     var time ="Expiring:" + h + ":" + m + ":" + s + " "

  
      timeLeft = {
        hours: padTo2Digits(23 - date.getHours()),
        minutes: padTo2Digits(59 - date.getMinutes()),
        seconds: padTo2Digits(59 - date.getSeconds()),
        
      };
    

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [year] = useState(new Date().getFullYear());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]} {interval}{" "}
      </span>
    );
  });

  function showTime(){
    var time = timeLeft.hours + ":" + timeLeft.minutes + ":" + timeLeft.seconds + " "
    return time
  }


  return (
   
      <div className="d-flex justify-content-between">
    <h6 className="  "><i class="bi bi-tags"></i> Deals of the Day</h6>
   

    <h6 className="text-center">Expires in {showTime()}</h6>
    <h6 className="   ">View All </h6>
    </div>
     
      
  );
}

export default DealDeadline;