import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, createHttpLink } from '@apollo/client';
import './style.css'
import 'react-calendar/dist/Calendar.css';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import {ApolloLink} from "apollo-link"
import ErrorHandler from './ErrorHandler'
import HomePage from './home'
import {HomeContainer} from './HomeContainer'
import {LoginContainer} from './LoginContainer'
import {RegisterContainer} from './RegisterContainer'
import {RegisterPage} from './register'
import {RegisterPartnerPage} from './registerPartner'
import {NavBar} from "./navbar.js"
import AuthenticationPage from './Authentication';
import VendorAppPage from "./vendor/vendorapp"
import {VendorContainer} from "./vendor/VendorContainer"
import {LogoutContainer} from "./LogoutContainer"
import {LogoutPage} from "./components/logout" 
import {RedirectLoginPage} from "./components/redirectLogin"
import StoresPage from "./stores/stores"
import ProductPage from "./stores/product"
import ShoppingCartPage from "./stores/ShoppingCartPage"
import FooterNav from "./footer"
import {EmailVerificationContainer} from "./EVerifyContainer"
import EmailVerificationPage from "./everify"
import {AccountContainer} from "./AccountContainer"
import {createBrowserHistory} from 'history';  

const server = process.env.REACT_APP_URL

console.log = function () {};

//const httpLink = createHttpLink({uri: 'http://152.70.138.147:5001', credentials: 'include'}) 
const httpLink = createHttpLink({uri: server, credentials: 'include'}) 
export const history = createBrowserHistory();
var errorSwitch = true

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token');
  console.log(token)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "x-token": token ? `${token}` : "",
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      //console.log( `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)

      ErrorHandler.handleError(message, locations, path)
    );
  if (networkError) {
    errorSwitch = false
    load()
    console.log(`[Network error]: ${networkError}`);
  }
});

document.addEventListener("clearCache", clearCache, false)

let link =  ApolloLink.from([errorLink, authLink.concat(httpLink)])
const client = new ApolloClient({
  link:  link,
  cache: new InMemoryCache()
});
client.onResetStore(link.writeDefaults);

function clearCache(){
  client.clearStore() 
}
const AppController = props => {
  return (
    <div className="AppController overFlowControl">
       <div className="">
       <NavBar />
      </div>

    <div className="AppBody">

    <Switch>
    <HomeContainer exact path="/" component={HomePage}/> 
    <RegisterContainer exact path ="/register" component={RegisterPage}/>
    <RegisterContainer exact path ="/registerPartner" component={RegisterPartnerPage}/>
    <LoginContainer  exact path="/login" component={AuthenticationPage} />
    <VendorContainer exact path="/vendor" component={VendorAppPage} />
    <LogoutContainer exact path="/logout" component={LogoutPage} />
    <LogoutContainer exact path="/redirectLogin" component={RedirectLoginPage} />
    <HomeContainer exact path="/store/*" component= {StoresPage} />
    <HomeContainer exact path="/product/*" component= {ProductPage} />
    <HomeContainer exact path="/cart" component= {ShoppingCartPage} />
    <AccountContainer exact path="/account" />
    <EmailVerificationContainer exact path="/everify/*" component={EmailVerificationPage} />
    <Route path="*" component={() => "404 NOT FOUND"} />
    </Switch>
    </div>

    <div>
    </div>

    <FooterNav />
    </div>
  );
}



function ErrorAppController() {
  return (
    <div className="ErrorAppController">
    <Switch>
    <Route exact path="/" component={() => ""}/> 
    <Route path="*" component={() => ""} />
    </Switch>
    </div>
  );
}

const load = () =>{
  if(errorSwitch){
    ReactDOM.render(
      <ApolloProvider client={client}>
      <BrowserRouter>
      <AppController />

      </BrowserRouter>
      </ApolloProvider>,
      rootElement
    );
  }else{
    ReactDOM.render(
      <ApolloProvider client={client}>
      <BrowserRouter history={history}>
      <ErrorAppController />

      </BrowserRouter>
      </ApolloProvider>,
      rootElement
    );

  }

}
const rootElement = document.getElementById("root");
load()

