
export const WelcomeAccountPage = props => {
  return (
    <>
          <div className="row-fluid">
 <h3 className="text-center fontSmallCaps colorG">Account Management</h3>
         
          </div>
    </>
  );


};


