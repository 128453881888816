import React, { useState, useRef, Fragment } from "react";

import { trackWindowScroll } from "react-lazy-load-image-component";
import Item from "./item";

import ReactCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";



let mobileBreakPoints = {
  stagePadding: {
    paddingLeft: 20, // in pixels
    paddingRight: 20
  },
  showSlideInf: true,
  responsive: {
    270: {
      items: 2
    },
    400: {
      items: 2
    },
    500: {
      items: 3
    },
    820: {
      items: 4
    },
    1100: {
      items: 5
    },
    1330: {
      items: 6
    }
  }
};

let desktopBreakpoints = {
  stagePadding: {
    paddingLeft: 20, // in pixels
    paddingRight: 20
  },
  responsive: {
    270: {
      items: 1
    },
    400: {
      items: 2
    },
    500: {
      items: 3
    },
    820: {
      items: 4
    },
    1100: {
      items: 5
    },
    1330: {
      items: 6
    }
  }
};

let options = {
  mouseTrackingEnabled: true,
  preservePosition: true
};

function Projects({ scrollPosition, ...props }) {

  let aliceRef = useRef();
  const [isloading, setloading] = useState(true);
  let [state, setState] = useState({
    currentIndex: 0,
    itemsInSlide: 1
  });

  function cacheImage(data) {
    data.forEach(d => {
      new Promise((res, rej) => {
        const image = new Image();
        image.src = d.download_url;
        image.onload = res();
        image.onerror = rej();
      });
    });
    setloading(false);
  }
  const slidePrevPage = () => {
    const currentIndex = state.currentIndex - state.itemsInSlide;
    console.log(currentIndex);

    setState(prev => ({ ...prev, currentIndex }));
  };

  const slideNextPage = () => {
    const { itemsInSlide } = state;
    let length = props.products.length;
    let currentIndex = state.currentIndex + itemsInSlide;
    if (currentIndex > length) currentIndex = length;

    setState(prev => ({ ...prev, currentIndex }));
  };

  const handleOnSlideChange = event => {
    const { itemsInSlide, item } = event;
    console.log(itemsInSlide + "_" + item);

    setState(prev => ({ ...prev, itemsInSlide, currentIndex: item }));
  };

  const sliderOptions = {
    ...options,
    ...(props.isMobile ? mobileBreakPoints : desktopBreakpoints)
  };

    let data = []
  React.useEffect(() => {
    cacheImage(data);
  }, []);

  return (
    <div className="App">
    
      {/* <button onClick={() => flickingRef.current.next()}>Set circular</button> */}
      {isloading ? (
        <h3>Loading...</h3>
      ) : (
        <Fragment>
          <ReactCarousel
             {...sliderOptions}
            ref={aliceRef}
      autoPlayInterval="30000"   autoPlayStrategy="none" infinite="true" autoPlay="true"
      disableButtonsControls="true"  mouseTracking  disableDotsControls="true"
            // onInitialized={e => console.log(e)}
          >
            {props.products.map(product => {
              return (
                <Item
                  scrollPosition={scrollPosition}
                  download_url={product.images[0]}
                  name={product.name}
                  id={product.id}
                  key={product.id}
                />
              );
            })}
          </ReactCarousel>
        </Fragment>
      )}

    </div>
  );
}

export default trackWindowScroll(Projects);

/*

            startIndex={state.currentIndex}
            slideToIndex={state.currentIndex}
            onInitialized={handleOnSlideChange}
            onSlideChanged={handleOnSlideChange}
            onResized={handleOnSlideChange}
            {...sliderOptions}
            keysControlDisabled
            buttonsDisabled
            
*/