class ShoppingCartManager {
  constructor() {

  }

  async addToShoppingCart(product){

    console.log("Adding to cart")
    let cart =  await this.getFromLocalStorage()
    let updatedShoppingCart = cart
    let match  = false
    console.log("Shopping Cart")
    console.log(cart)
    for(var counter = 0 ; counter <= cart.length -1; counter++){
      if(cart[counter].id === product.id) {
        updatedShoppingCart[counter].quantity += product.quantity
        match = true
      }
    }

    if(match){
      this.replaceShoppingCart(updatedShoppingCart)
    }else{

      this.saveDataToLocalStorage(product)
      var event = new Event('itemInserted');
      document.dispatchEvent(event);
    }

  }

  replaceShoppingCart(product){
    this.replaceDataToLocalStorage(product)
    var event = new Event('itemInserted');
   document.dispatchEvent(event);


  }



 replaceAllShoppingCart(product){
    this.replaceDataToLocalStorage(product)
  }


  getShoppingBag(){
    this.getFromLocalStorage()
  }





  replaceCoupon(coupon){
    localStorage.setItem('coupons', JSON.stringify(coupon));

  }

    clearShoppingCart(){
        localStorage.setItem('shoppingCart', '')
        var event = new Event('itemInserted');
        document.dispatchEvent(event);
    }

  saveDataToLocalStorage(data)
  {
    var a = [];
    // Parse the serialized data back into an aray of objects
      if(localStorage.getItem("shoppingCart") !== ""){
          a =  JSON.parse(localStorage.getItem('shoppingCart')) || [];
      }
    // Push the new data (whether it be an object or anything else) onto the array
    a.push(data);
    // Alert the array value
    // Re-serialize the array back into a string and store it in localStorage
    localStorage.setItem('shoppingCart', JSON.stringify(a));
    this.incrementCount()
  }

  replaceDataToLocalStorage(data)
  {
    localStorage.setItem('shoppingCart', JSON.stringify(data));
    this.incrementCount()
  }

  incrementCount(){
    let count = localStorage.getItem('count')
    if(count == null){
      localStorage.setItem('count', "1")
    }else{
      let numCount = parseInt(count)
      numCount += 1
      localStorage.setItem('count', numCount.toString())
    }
  }
  getCount(){
    let count = localStorage.getItem('count')
    if(count == null){
      return 0
    }else{
      return parseInt(count)
    }
  }

  async getFromLocalStorage(){
    var a = [];
    // Parse the serialized data back into an aray of objects
      if(localStorage.getItem("shoppingCart") !== ""){
          a = await JSON.parse(localStorage.getItem('shoppingCart')) || [];
      }
    
    console.log(a)
    return a
  }


}




export default new ShoppingCartManager();
