import {Button } from 'react-bootstrap'
function FooterNav(props) {
console.log(props)
return(
<>

<footer className="footerControl">
  </footer>

</>
);
}


export default FooterNav
