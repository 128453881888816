import {Card} from 'react-bootstrap'
import { useHistory } from "react-router-dom";
import logo from '../../img/everyday.png'

export const WelcomeVendorPage = props => {
    let history = useHistory()
  return (
    <>
          <div className="row-fluid">
           <Card.Header className=""> <h5 className=" text-center ">Welcome To Market Place</h5></Card.Header>
            
          <div className=" text-center">
          <img src={logo} className="img-fluid"   alt="logo" width="1080px" height="500px" />
        </div>
          </div>
    </>
  );


};


